import "./VideoPlayer.scss";
import Player from "./Player/Player";
import ThumbVideo from "./ThumbVideo/ThumbVideo";
import { ReactComponent as AnimalsIcon } from "assets/Images/foot.svg";
import { ReactComponent as ShapesIcon } from "assets/Images/star.svg";
import { ReactComponent as ColorsIcon } from "assets/Images/colors.svg";
import { useEffect, useState } from "react";
import { playerApi } from "api";
import { useTagsList } from "hooks";

const VideoPlayer = () => {
  const [list, setList] = useState();
  const [video, setVideo] = useState();
  const [play, setPlay] = useState(false);
  const [channelIcon, setUserIcon] = useState("");
  const [channelName, setChannelName] = useState("");
  const [channelLink, setChannelLink] = useState("");

  useEffect(() => {
    playerApi
      .getVideos()
      .then((videos) => {
        setList(videos);
        setVideo(videos[0]);
      })
      .catch((error) => {
        setList(["", "", ""]);
        setVideo(null);
      });
  }, []);

  useEffect(() => {
    if (video) {
      setUserIcon(video.channelInfo.channelIcon);
      setChannelName(video.channelInfo.channelName ? video.channelInfo.channelName : video.name);
      setChannelLink(video.channelInfo.channelLink);
    }
  }, [video]);

  const handleThumbOnClick = (video) => {
    setVideo(video);
    setPlay(true);
  };

  return (
    <>
      {list && (
        <div className="main-page-wrapper">
          <Player
            video={video}
            play={play}
            onPlay={setPlay}
            channelIcon={channelIcon}
            channelName={channelName}
            channelLink={channelLink}
          />
          <div className="thumb-videos">
            <ThumbVideo
              image={list[0] ? `${list[0].thumbnails.fileNames[list[0].thumbnails.selectedIndex]}` : ""}
              icon={<AnimalsIcon />}
              label={list[0]?.name}
              onClick={() => list[0] && handleThumbOnClick(list[0])}
            />
            <ThumbVideo
              image={list[1] ? `${list[1].thumbnails.fileNames[list[1].thumbnails.selectedIndex]}` : ""}
              icon={<ShapesIcon />}
              label={list[1]?.name}
              onClick={() => list[1] && handleThumbOnClick(list[1])}
            />
            <ThumbVideo
              image={list[2] ? `${list[2].thumbnails.fileNames[list[2].thumbnails.selectedIndex]}` : ""}
              icon={<ColorsIcon />}
              label={list[2]?.name}
              onClick={() => list[2] && handleThumbOnClick(list[2])}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VideoPlayer;
