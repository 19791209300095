import React, { useEffect } from "react";
import { useAuthContext } from "auth";
import translate from "i18n/translate";
import SignupAuthForm from "./SignupAuthForm";

const CreatorSignup = () => {
  const { error, setError, signupCreator, googleCreatorLogin } = useAuthContext();

  useEffect(() => {
    setError("");
  }, [setError]);

  return (
    <SignupAuthForm
      label={translate("creator_signup_SignupAuthForm_label")}
      signinLink="/creator/signin"
      error={error}
      onSubmit={signupCreator}
      onGoogle={googleCreatorLogin}
    />
  );
};

export default CreatorSignup;
