import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import { ReactComponent as PulsBtn } from "assets/Icons/BigPlus.svg";
import { Header, MainLabel } from "components";
import { SavedVideo } from "components";
import { AlertDialog } from "components";
import { useNavigate } from "react-router";
import { videoApi } from "api";
import "./MyVideo.scss";
import translate from "i18n/translate";

const MyVideo = () => {
  const [list, setList] = useState([]);
  const [alertDialogData, setAlertDialogData] = useState(null);
  const navigate = useNavigate();

  const getVideoList = useCallback(() => {
    videoApi &&
      videoApi
        .getByUser()
        .then((videos) => setList(videos))
        .catch(() => setList([]));
  }, []);

  useEffect(() => {
    getVideoList();
  }, [getVideoList]);

  const openDeleteVideoDialog = (video) => {
    setAlertDialogData({
      onOk: () => {
        videoApi
          .deleteOne(video)
          .then(() => getVideoList())
          .catch();
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "myVideo_openDeleteVideoDialog_title",
      message: "myVideo_openDeleteVideoDialog_message",
    });
  };

  const handleEdit = (video) => {
    navigate(`/editor/${video._id}`);
  };

  return (
    <>
      <Header />
      <div className="all-video-wrapper">
        <MainLabel label={translate("myVideo_MainLabel_label")} />
        <Button
          className="upload-button"
          component={NavLink}
          to="/uploader"
          endIcon={<PulsBtn />}
          title={translate("myVideo_button_title_addVideo")}
        />
        {list &&
          list.map((video) => (
            <div className="all-video-box" key={video._id}>
              <SavedVideo video={video} onDelete={openDeleteVideoDialog} onEdit={handleEdit} />
            </div>
          ))}
        <AlertDialog alertDialogData={alertDialogData} />
      </div>
    </>
  );
};

export default MyVideo;
