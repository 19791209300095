import React, { useEffect, useState } from "react";
import { usersApi } from "api";
import translate from "i18n/translate";
import { useAuthContext } from "auth";
import { Header, InputStyled, MainLabel, UserIcon } from "components";
import { Button } from "@mui/material";
import "./EditAccount.scss";

const EditAccount = () => {
  const { user, getMe } = useAuthContext();
  const [channelIcon, setChannelIcon] = useState("");
  const [channelName, setChannelName] = useState("");
  const [channelLink, setChannelLink] = useState("");
  const [channelIconFile, setChannelIconFile] = useState();
  const [channelIconUrl, setChannelIconUrl] = useState("");
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

  useEffect(() => {
    if (user?.profile) {
      setChannelIcon(user.profile.channelIcon);
      setChannelName(user.profile.channelName);
      setChannelLink(user.profile.channelLink);
    }
  }, [user]);

  useEffect(() => {
    setSaveButtonEnabled(
      channelIconFile || channelName !== user.profile.channelName || channelLink !== user.profile.channelLink
    );
  }, [channelIconFile, channelName, channelLink, user.profile.channelName, user.profile.channelLink]);

  const handleChangeChannelIcon = (file) => {
    setChannelIconFile(file);
    setChannelIconUrl(URL.createObjectURL(file));
  };

  const updateUser = async () => {
    if (channelIconFile) {
      const files = [{ name: "channelIcon", data: channelIconFile }];
      await usersApi.uploadFiles(user._id, files);
    }
    const fields = {
      channelName: channelName,
      channelLink: channelLink,
    };
    await usersApi.update(user._id, fields);

    getMe();
    channelIconUrl && URL.revokeObjectURL(channelIconUrl);
    setChannelIconFile(null);
    setChannelIconUrl(null);
  };

  return (
    <>
      <Header />
      <div className="edit-account-wrapper">
        <div className="editor-content">
          <div className="user-icon-box">
            <UserIcon
              image={channelIconUrl ? channelIconUrl : channelIcon}
              onChange={(file) => handleChangeChannelIcon(file)}
            />
          </div>
          <InputStyled
            label={translate("editAccount_inputStyled_label_channelName")}
            placeholder={translate("editAccount_inputStyled_placeholder_channelName")}
            value={channelName}
            onChange={(e) => setChannelName(e.target.value)}
          />
          <InputStyled
            label={translate("editAccount_inputStyled_label_channelLink")}
            placeholder={translate("editAccount_inputStyled_placeholder_channelLink")}
            value={channelLink}
            onChange={(e) => setChannelLink(e.target.value)}
          />
        </div>
        <Button className="save-btn" disabled={!saveButtonEnabled} onClick={updateUser}>
          {translate("editor_savebuttom")}
        </Button>
      </div>
    </>
  );
};

export default EditAccount;
