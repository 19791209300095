import React from "react";
import { ReactComponent as EmptyAvatar } from "assets/Icons/Avatar.svg";
import "./User.scss";
import translate from "i18n/translate";

const User = ({ avatarIcon, userName, children, onClick }) => {
  return (
    <div className="user" onClick={onClick}>
      <div className="avatar">{!avatarIcon ? <EmptyAvatar /> : <img src={`${avatarIcon}`} alt="" />}</div>
      <div className="name">{!userName ? translate("header_user_name") : userName}</div>
      {children}
    </div>
  );
};

export default User;
