import { useAuthContext } from "auth";
import { Navigate, Outlet, useLocation } from "react-router";

const ProtectedRoute = ({ allowedRoles }) => {
  const { user, isLoading } = useAuthContext();
  const location = useLocation();

  if (isLoading) {
    return null;
  }


  if (!user) return <Navigate to="/" state={{ from: location }} replace />;
  if (user?.roles?.find((role) => allowedRoles.includes(role))) return <Outlet />;
  if (user?.roles?.find((role) => !allowedRoles.includes(role)))
    return <Navigate to="/noAccess" state={{ from: location }} replace />;
};

export default ProtectedRoute;
