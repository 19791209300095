import React, { useContext } from "react";
import { SelectItem } from "components";
import { LocaleContext } from "i18n/LanguageProvider";
import { ReactComponent as IconGoogle } from "assets/Icons/Google.svg";
import Checkbox from "components/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import { Button, TextField, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import { ReactComponent as IconAvatar } from "assets/Icons/Avatar.svg";
import { ReactComponent as IconLock } from "assets/Icons/Lock.svg";
import { useCredentialsValidation } from "hooks";
import translate from "i18n/translate";
import "../AuthPage.scss";

const SigninAuthForm = ({ signupLink, label, onSubmit, onGoogle, error }) => {
  const { supportedLocales, locale, onChangeLocale } = useContext(LocaleContext);
  const { validationErrors, credentials, setCredential, validateCredentials, validationSuccessful } =
    useCredentialsValidation();

  const handleFormSubmit = () => {
    onSubmit(credentials);
  };

  return (
    <div className="auth-page-wrapper">
      <div className="lang-switcher-style">
        <SelectItem
          value={locale.value}
          selectLabel={locale.name}
          onChange={onChangeLocale}
          menuItems={supportedLocales}
        />
      </div>

      <div className="auth-form">
        <p className="page-label">{label}</p>
        <Button className="big-button" onClick={onGoogle} startIcon={<IconGoogle />}>
          {translate("signInAuthForm_button_enterGoogle")}
        </Button>
        <div className="separator">{translate("signInAuthForm_separator")}</div>

        <div className="text-field-box">
          <TextField
            autoFocus
            required
            name="email"
            type="email"
            onChange={(e) => setCredential("email", e.target.value)}
            onBlur={(e) => e.target.value && validateCredentials()}
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email}
            className="text-field"
            placeholder={translate("signInAuthForm_textField_email_placeholder")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconAvatar />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password"
            type="password"
            onChange={(e) => setCredential("password", e.target.value)}
            onBlur={(e) => e.target.value && validateCredentials()}
            error={Boolean(validationErrors.password)}
            helperText={validationErrors.password}
            className="text-field"
            placeholder={translate("signInAuthForm_textField_password_placeholder")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconLock />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="check-forgot-wrapper">
          <Checkbox
            label={translate("signInAuthForm_сheckbox_label")}
            onChange={(value) => setCredential("save", value)}
          />
          <Link to="/password/forgot" className="forgot-password">
            {translate("signInAuthForm_link_forgotPassword")}
          </Link>
        </div>

        <Button className="big-button" disabled={!validationSuccessful} onClick={() => handleFormSubmit()}>
          {translate("signInAuthForm_button_continue")}
        </Button>

        <div className="no-account">
          <span>{translate("signInAuthForm_span_noAccaunt")}</span>
          <Link to={signupLink} className="link">
            {translate("signInAuthForm_link_registration")}
          </Link>
        </div>

        {error && <Alert severity="error">{translate(error)}</Alert>}
      </div>
    </div>
  );
};

export default SigninAuthForm;
