import React from "react";

const Tag = ({ tag, selected, onClick }) => {
 
  return (
    <div className="tag-select-wrapper" onClick={() => onClick(tag)}>
      <div className={`tag ${selected ? "tag-selected" : ""}`}>{tag?.name}</div>
    </div>
  );
};

export default Tag;
