import React from "react";
import "./Tag.scss";

const Tag = ({ videoTag, tagName, style }) => {
  return (
    <div className="not-active-tag-wrapper" style={style}>
      <div className="not-active-tag-label">{tagName}</div>
      <div className="not-active-tag-param">{videoTag.weight}</div>
    </div>
  );
};

export default Tag;
