import React, { useEffect, useState } from "react";
import DependencyTagEdit from "./DependencyTagEdit/DependencyTagEdit";
import DependencyTag from "./DependencyTag/DependencyTag";
import InputWithPopup from "../InputWithPopup/InputWithPopup";
import { ReactComponent as RemoveIcon } from "assets/Icons/Remove.svg";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { Button } from "@mui/material";
import "./TagTile.scss";

const TagTile = ({
  tag,
  tagsList,
  onCreateTag,
  selected,
  onClick,
  onSave,
  onDelete,
  dependentVideos,
  dependentTags,
}) => {
  const [dependencies, setDependencies] = useState([]);
  const [usedTagsList, setUsedTagsList] = useState([]);
  const [alertDialogData, setAlertDialogData] = useState(null);

  useEffect(() => {
    tag && setDependencies(tag.dependencies);
  }, [tag]);

  useEffect(() => {
    setUsedTagsList(
      dependencies.map((dependencyTag) => {
        return { ...tagsList.find((tag) => tag._id === dependencyTag.tagId) };
      })
    );
  }, [dependencies, tagsList]);

  const getTagNameById = (tagId) => tagsList.find((tag) => tag._id === tagId)?.name;

  const handleCreateAndAddToDependencies = (tagName) => {
    onCreateTag(tagName).then((tag) => handleAddToDependencies(tag));
  };

  const handleAddToDependencies = (tagFromList) => {
    if (tagFromList) {
      const dependencyTag = { tagId: tagFromList._id };
      dependencies.push(dependencyTag);
      onSave(tag._id, { dependencies: dependencies });
    }
  };

  const handleDependencyDelete = (dependencyTag) => {
    const index = dependencies.indexOf(dependencyTag);
    dependencies.splice(index, 1);
    onSave(tag._id, { dependencies: dependencies });
  };

  const openDeleteTagDialog = () => {
    setAlertDialogData({
      onOk: () => {
        setAlertDialogData(null);
        onDelete(tag);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Удаление тега",
      message: "Удалить тег?",
    });
  };

  return (
    <div className={`tagging-video-tile-wrapper ${selected ? "video-tile-wrapper-active" : ""}`}>
      <Button
        className={`remove-btn ${dependentVideos.length || dependentTags.length ? "remove-btn-disabled" : ""}`}
        disabled={dependentVideos.length || dependentTags.length}
        endIcon={<RemoveIcon />}
        onClick={openDeleteTagDialog}
        title={dependentVideos.length || dependentTags.length ? "Используется ... " : "Удалить"}
      />

      <div className="tile-container" onClick={(e) => onClick && onClick(tag)}>
        {selected && <img className="tile-img tile-img-selected" src="" alt="" />}
        {!selected && <img className="tile-img" src="" alt="" />}

        <div className={`tile-video-name ${selected ? "tile-video-name-active" : ""}`}>{tag?.name}</div>
      </div>
      <div className="video-tags">
        <div className="none-active-tag">
          <div className={`tag-label ${selected ? "tag-label-active" : ""}`}>
            {!selected &&
              dependencies.map((dependencyTag, i) => (
                <DependencyTag key={i} tag={dependencyTag} tagName={getTagNameById(dependencyTag.tagId)} />
              ))}
            {selected && (
              <>
                {dependencies.map((dependencyTag, i) => (
                  <DependencyTagEdit
                    active={true}
                    key={i}
                    tag={dependencyTag}
                    tagName={getTagNameById(dependencyTag.tagId)}
                    onDelete={handleDependencyDelete}
                  />
                ))}
                <InputWithPopup
                  rows={tagsList}
                  usedRows={usedTagsList}
                  onSelect={handleAddToDependencies}
                  onCreate={handleCreateAndAddToDependencies}
                  placeholder="Добавить тег"

                />
              </>
            )}
          </div>
        </div>
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </div>
  );
};

export default TagTile;
