import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const translate = (id) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const intl = useIntl();
    if (id.includes("placeholder") || id.includes("title")) return intl.formatMessage({id})

return (<FormattedMessage id={id}/>)
}

export default translate;