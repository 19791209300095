import React, { useContext } from "react";
import { SelectItem } from "components";
import { LocaleContext } from "i18n/LanguageProvider";
import { Link, useParams } from "react-router-dom";
import { Button, TextField, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import { ReactComponent as IconLock } from "assets/Icons/Lock.svg";
import { useCredentialsValidation } from "hooks";
import translate from "i18n/translate";
import usePasswordRecovery from "auth/usePasswordRecovery";
import "./AuthPage.scss";

const NewPassword = () => {
  const { supportedLocales, locale, onChangeLocale } = useContext(LocaleContext);
  const { validationErrors, credentials, setCredential, validateCredentials, validationSuccessful } =
    useCredentialsValidation();
  const { status, resetPassword } = usePasswordRecovery();

  const params = useParams();

  return (
    <div className="auth-page-wrapper">
      <div className="lang-switcher-style">
        <SelectItem
          value={locale.value}
          selectLabel={locale.name}
          onChange={onChangeLocale}
          menuItems={supportedLocales}
        />
      </div>
      <div className="auth-form">
        {!status.success && (
          <>
            <p className="page-label">{translate("newPassword_pageLabel_label")}</p>
            <p className="page-text">{translate("newPassword_pageText_text")}</p>
            <div className="text-field-box">
              <TextField
                name="password"
                type="password"
                value={credentials.password}
                onChange={(e) => setCredential("password", e.target.value)}
                onBlur={(e) => e.target.value && validateCredentials()}
                error={Boolean(validationErrors.password)}
                helperText={validationErrors.password}
                className="text-field"
                placeholder={translate("newPassword_textField_password_placeholder")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                name="passwordRepeat"
                type="password"
                value={credentials.passwordRepeat}
                onChange={(e) => setCredential("passwordRepeat", e.target.value)}
                onBlur={(e) => e.target.value && validateCredentials()}
                error={Boolean(validationErrors.passwordRepeat)}
                helperText={validationErrors.passwordRepeat}
                className="text-field"
                placeholder={translate("newPassword_textField_repeatPassword_placeholder")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconLock />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button
              className="big-button"
              disabled={!validationSuccessful}
              onClick={() => resetPassword(params.token, credentials.password, locale.value)}
            >
              {translate("newPassword_button_continue")}
            </Button>

            <div className="no-account">
              <span>{translate("newPassword_span_rememberPassword")}</span>
              <Link to={"/creator/signin"} className="link">
                {translate("newPassword_signinLinkText_text")}
              </Link>
            </div>
          </>
        )}

        {status.error && <Alert severity="error">{status.error}</Alert>}

        {status.success && !status.error && (
          <p className="success-label">{translate("newPassword_successLabel_label")}</p>
        )}
        {status.success && !status.error && (
          <Link to={"/creator/signin"} className="redirect-success-link ">
            {translate("newPassword_signinLinkText_text")}
          </Link>
        )}
      </div>
    </div>
  );
};

export default NewPassword;
