import "./policy.scss";
import { NavLink } from "react-router-dom";

const UaPrivacyPolicy = () => {
  return (
    <div className="article-wrapper">
      <div className="article-body">
        <div className="article-title">Політика конфіденційності</div>
        <div className="article-date">Оновлено 13.05.2022</div>
        <div className="article-subtitle">Загальні положення</div>
        <div className="article">
          З метою підвищення якості обслуговування та безпеки зберігання даних наших користувачів, інформуємо вас про
          те, які саме дані ми збираємо і яким чином їх використовуємо на нашому сайт
          <NavLink to="/">
            {" "}
            <NavLink to="/"> https://bulbee.tv</NavLink>
          </NavLink>
          , і мобільних додатках (далі – Bulbee). Ми з повагою ставимось до конфіденційної інформації будь-якої особи,
          яка відвідує наш сайт, або користується нашим мобільним додатком тому на підставі вимог чинного законодавства
          України, а саме Закону України від 01 червня 2010 року № 2297-VI «Про захист персональних даних» та регламенту
          ради Європейського Союзу, Європейської Комісії та Європейського парламенту (GDPR) нами була підготовлена дана
          Політика конфіденційності (далі – Політика).
        </div>
        <div className="article">
          Ця Політика не поширюється на будь-які мобільні додатки третіх осіб, які ви можете використовувати, включаючи
          ті, які пов’язані із додатками Bulbee, а також на сайти, перехід на які здійснюється за допомогою
          гіперпосилань, розміщених на{" "}
          <NavLink to="/">
            {" "}
            <NavLink to="/"> https://bulbee.tv</NavLink>
          </NavLink>
          .
        </div>
        <div className="article">
          Повідомляємо, що ми застосовуємо файли сookie. Із кожним вашим відвідуванням Bulbee ми можемо збирати
          інформацію, що стосується пристроїв, які ви використовуєте, та мереж, до яких ви приєднюєтесь, коли
          користуєтеся нашими сервісами. Це може включати таку інформацію, як: IP-адреса, модель пристрою, інформація
          для входу в обліковий запис, тип браузера та версія, тип та вид плагінів браузера, операційна система та
          платформа, інформація про ваші відвідування, у тому числі, маршрут переміщення за URL-адресами на, через чи з
          <NavLink to="/"> https://bulbee.tv</NavLink>, продукти, які ви переглядали або шукали, помилки завантаження,
          довжина відвідування певних сторінок тощо. Після завантаження та встановлення нашого мобільного додатку ми
          можемо збирати такі дані, як номер мобільного телефону. Ми збираємо таку інформацію шляхом використання різних
          технологій, у тому числі, cookie. Файли сookie – це невеликі блоки даних, які розміщуються на тимчасове
          зберігання на жорсткий диск вашого комп’ютера або у мобільний пристрій для того, щоб ви могли більш ефективно
          користуватися нашим сайтом. Якщо ви надаєте згоду, то це дозволяє нам використовувати файли cookie кожного
          разу, коли ви користуєтесь
          <NavLink to="/"> https://bulbee.tv</NavLink>.
        </div>
        <div className="article-subtitle">Перелік персональних даних</div>
        <div className="article">
          Перелік персональних даних користувачів, які можуть бути зібрані, збережені та використані під час роботи з
          Bulbee:
        </div>
        <ul className="article-list">
          <li>ваші прізвище, ім’я та по батькові, номер телефону, електронна адреса або інші контактні дані;</li>
          <li>
            інформація про ваш комп’ютер чи мобільний пристрій, включаючи IP-адресу, географічне розташування, тип і
            версію браузера і операційної системи;
          </li>
          <li>
            інформація про ваші відвідування і використання Bulbee, включаючи тривалість відвідування, перегляди
            сторінок і шляхи навігації;
          </li>
          <li>інформація, яку ви вводите під час користування Bulbee;</li>
          <li>
            інформація, що міститься в будь-яких повідомленнях, які ви відправляєте нам по електронній пошті або через
            Bulbee, включаючи комунікаційний контент і метадані;
          </li>
          <li>будь-яку іншу особисту інформацію, яку ви нам надсилаєте та вносите під час реєстрації на Bulbee.</li>
        </ul>
        <div className="article-subtitle">Цілі опрацювання персональних даних</div>
        <div className="article">
          Персональні дані, надані вами через Bulbee, будуть використовуватись для наступних цілей:
        </div>
        <ul className="article-list">
          <li>адміністрування Bulbee і бізнесу;</li>
          <li>
            персоналізація Bulbee для вас, дозволяючи використовувати наші послуги на сайті{" "}
            <NavLink to="/"> https://bulbee.tv</NavLink>, та мобільних додатках;
          </li>
          <li>відправка користувачу повідомлень;</li>
          <li>відправка нотифікацій на мобільний пристрій, за умови згоди користувача на їхнє отримання;</li>
          <li>
            відправка повідомлень електронною поштою, які користувачі спеціально запросили, у т.ч. для створення
            облікового запису або дій з обліковим записом (відновлення пароля, додавання чи зміна логіна користувача
            тощо);
          </li>
          <li>
            надання третім сторонам статистичної інформації про наших користувачів (але ці треті сторони не зможуть
            ідентифікувати будь-якого окремого користувача за цією інформацією);
          </li>
          <li>розгляд запитів і скарг, зроблених користувачами або про користувачів із приводу Bulbee;</li>
          <li>забезпечення безпеки Bulbee та запобігання шахрайству;</li>
          <li>
            перевірка дотримання умов, що регулюють використання Bulbee (включаючи моніторинг особистих повідомлень,
            відправлених через нашу службу обміну приватними повідомленнями);
          </li>
          <li>а також інші види використання.</li>
        </ul>
        <div className="article">
          Крім того, ми використовуємо ваші персональні дані у маркетингових цілях відповідно до закону. При
          використанні ваших персональних даних для прямого маркетингу (комерційна розсилка і маркетингові повідомлення
          про нові продукти, послуги та пропозиції, які, на нашу думку, будуть вам цікаві), ми включаємо посилання,
          перейшовши за яким ви можете відмовитися від підписки на такі повідомлення в майбутньому.
        </div>
        <div className="article-subtitle">Доступ до ваших персональних даних</div>
        <div className="article">
          Ми вправі розкрити вашу особисту інформацію у виключних випадках тільки для цілей, викладених у цій Політиці,
          будь-якому з наших співробітників, посадових осіб, страховиків, професійних консультантів, агентів.
        </div>
        <div className="article">
          Повідомляємо, що надані вами відомості включені до баз персональних даних, власником яких є приватний
          підприємець Наталія Колчева, юридична адреса: Україна, м. Харків, пр-т Людвіга Свободи, 40/49 з метою їх
          обробки, для ведення обліку в межах, передбачених законодавством. Для виконання вищезазначеної мети ваші дані
          можуть надаватись третім особам, безпосередньо задіяним в обробці цих даних.
        </div>
        <div className="article-subtitle">
          Персональні дані можуть бути передані третім особам у наступних випадках:
        </div>
        <ul className="article-list">
          <li>надання вами однозначної згоди на таку передачу;</li>
          <li>необхідності укладення чи виконання правочину між нами та третьою особою на вашу користь;</li>
          <li>необхідності для формування та здійснення правових процедур (претензії, позови, судові процеси тощо);</li>
          <li>за наявності важливих підстав суспільного інтересу;</li>
          <li>
            за обґрунтованими запитами державних органів, які мають право вимагати й одержувати такі дані та інформацію.
          </li>
        </ul>
        <div className="article">
          За винятком випадків, передбачених у цій Політиці, ми зобов’язуємося не надавати вашу особисту інформацію
          третім особам.
        </div>
        <div className="article-subtitle">Період зберігання персональних даних</div>
        <div className="article">
          Ваша згода на опрацювання персональних даних діє протягом невизначеного строку. Строк зберігання ваших
          персональних даних не обмежений. Ваші персональні дані обробляються у строк, не більший ніж це необхідно
          відповідно до їх законного призначення та цілей, визначених цією Політикою.
        </div>
        <div className="article-subtitle">Права користувачів</div>
        <div className="article">При наданні нам ваших персональних даних для опрацювання, у вас є наступні права:</div>
        <ul className="article-list">
          <li>вимагати доступ до ваших персональних даних;</li>
          <li>вимагати виправлення будь-яких невірних персональних даних про вас;</li>
          <li>вимагати видалення ваших персональних даних;</li>
          <li>
            на обмеження та заперечення проти опрацювання ваших персональних даних, а також право на мобільність даних;
          </li>
          <li>відкликати у будь-який момент вашу згоду на опрацювання персональних даних.</li>
        </ul>
        <div className="article">
          Для реалізації вказаних прав вам необхідно звернутися до нас із письмовою заявою, направленою на наступну
          електронну адресу: <a href="mailto: support@bulbee.tv">support@bulbee.tv</a>.
        </div>
        <div className="article">
          Звертаємо вашу увагу, що робота деяких компонентів Bulbee, для яких необхідна наявність ваших персональних
          даних, може бути припинена з моменту їх видалення.
        </div>
        <div className="article">
          Наші сайт <NavLink to="/"> https://bulbee.tv</NavLink> та мобільні додатки призначені та розроблені для дітей
          віком до 14 років. Опрацювання персональних даних неповнолітніх осіб, у віці від 14 до 18 років здійснюється
          виключно за наявності згоди батьків (усиновлювачів) або піклувальників.
        </div>
        <div className="article">
          Якщо у вас є скарга щодо будь-якого опрацювання ваших персональних даних, ви можете звернутися до нас, до
          Уповноваженого Верховної Ради України з прав людини або до суду.
        </div>
        <div className="article-subtitle">Безпека даних</div>
        <div className="article">
          Повідомляємо вам, що для захисту ваших персональних даних вжито всіх необхідних заходів від несанкціонованого
          доступу, зміни, розкриття чи знищення із застосуванням відповідних технічних і організаційних інструментів.
        </div>
        <div className="article">
          Ми використовуємо технологію шифрування даних за технологією HTTPS від вашого браузера до сайту -
          <NavLink to="/"> https://bulbee.tv</NavLink>.
        </div>
        <div className="article">
          На жаль, необхідно визнати, що передача інформації через інтернет не завжди є повністю безпечною. Тому, як
          тільки ми отримаємо вашу інформацію, ми зробимо все можливе для захисту ваших персональних даних.
        </div>
        <div className="article">
          Користувач несе відповідальність за те, щоб пароль, який використовується для доступу до Bulbee, був безпечним
          і конфіденційним. Ми не будемо запитувати у вас пароль (крім випадків, коли ви входите на Bulbee).
        </div>
        <div className="article-subtitle">Зміни до умов Політики</div>
        <div className="article">
          Умови Політики Bulbee можуть змінитись. У такому разі необхідно періодично їх переглядати за адресою
          <NavLink to="/ua/privacy-policy"> https://bulbee.tv/ua/privacy-policy</NavLink>. Всі зміни набувають чинності
          з моменту їх публікації. Продовжуючи, використовувати Bulbee, ви підтверджує згоду з новими умовами Політики у
          редакції, чинній на момент використання Bulbee.
        </div>
        <div className="article-subtitle">Контактна інформація</div>
        <div className="article">
          Якщо у вас виникнуть будь-які запитання щодо нашої Політики, ви можете звернутись до нас за наступною
          електронною адресою: <a href="mailto: support@bulbee.tv">support@bulbee.tv</a>.
        </div>
        <div className="article-subtitle">Згода користувачів</div>
        <div className="article">
          Користуючись Bulbee, ви підтверджуєте, що ознайомлені з умовами цієї Політики і правами, наданими суб’єктам
          персональних даних згідно зі ст. 8 Закону України «Про захист персональних даних» та погоджуєтеся з
          викладеними умовами автоматично після проходження реєстрації на Bulbee.
        </div>
      </div>
    </div>
  );
};

export default UaPrivacyPolicy;
