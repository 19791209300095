import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { ReactComponent as RemoveIcon } from "assets/Icons/Remove.svg";
import { ReactComponent as EmptyIcon } from "assets/Icons/NoImage.svg";
import { Switcher } from "components";
import translate from "i18n/translate";
import { ROLE_ADMIN, ROLE_CREATOR } from "constants";
import "./UserCard.scss";

const UserCard = ({ user, updateUserRole, onDelete }) => {
  const [creatorRole, setCreatorRole] = useState(false);
  const [adminRole, setAdminRole] = useState(false);
  const [userIcon, setUserIcon] = useState("");

  useEffect(() => {
    user.roles.find((role) => role === ROLE_CREATOR) ? setCreatorRole(true) : setCreatorRole(false);
    user.roles.find((role) => role === ROLE_ADMIN) ? setAdminRole(true) : setAdminRole(false);
  }, [user.roles]);

  useEffect(() => {
    setUserIcon(user?.profile?.channelIcon || user?.profile?.picture);
  }, [user]);

  const onChangeRole = (user, newRole, oldRole) => {
    const newUserRoles = [];

    if (oldRole) {
      updateUserRole(user, newRole);
    } else {
      newUserRoles.push(newRole);
      updateUserRole(user, newRole);
    }
  };

  return (
    <div className="user-wrapper">
      <div className="user-icon">{userIcon ? <img src={userIcon} alt="" /> : <EmptyIcon />}</div>
      <div className="user-description">
        <div className="col-1">
          <div className="row-1">{user?.firstName}</div>
          <div className="row-2">{user?.lastName}</div>
          <div className="row-3">{user?.email}</div>
        </div>
        <div className="col-2">
          <Switcher
            label={ROLE_CREATOR}
            role={creatorRole}
            onChange={() => onChangeRole(user, ROLE_CREATOR, creatorRole)}
          />
        </div>
        <div className="col-3">
          <Switcher label={ROLE_ADMIN} role={adminRole} onChange={() => onChangeRole(user, ROLE_ADMIN, adminRole)} />
        </div>
        <div className="col-4">
          <Button
            className="buttons"
            endIcon={<RemoveIcon />}
            onClick={() => onDelete && onDelete(user)}
            title={translate("userCard_button_deleteUser_title")}
          />
        </div>
      </div>
    </div>
  );
};

export default UserCard;
