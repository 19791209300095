import axios from "axios";

const tagsApi = {
  getAll: () => {
    return axios.get("/api/tags").then((res) => res.data.tags);
  },

  deleteOne: (id) => {
    return axios.delete(`/api/tags/${id}`).then((res) => res.data.tag);
  },

  create: (fields) => {
    return axios
      .post(`/api/tags`, JSON.stringify(fields), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data.tag);
  },

  update: (id, fields) => {
    return axios
      .put(`/api/tags/${id}`, JSON.stringify(fields), { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data.tag);
  },

  uploadFiles: (id, files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append(file.name, file.data));
    return axios.put(`/api/tags/${id}`, formData).then((res) => res.data.tag);
  },
};

export default tagsApi;
