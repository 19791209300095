import React from "react";

import "./StatusBar.scss";

const StatusBar = ({ percentage }) => {
  return (
    <div className="status-bar-wrapper">
      <div className="status-bar" style={{ width: `${percentage}%` }}/>
      
      <div className="status-percentage">
        {`${percentage}%`}
      </div>
    </div>
  );
};

export default StatusBar;
