import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { StatusBar, CardStyled, InputStyled, MainLabel, ThumbnailsBlock, Header } from "components";
import { ReactComponent as UploadIcon } from "assets/Icons/Upload.svg";
import useUploadProgressData from "./useUploadProgressData";
import { path, thumbnails } from "utils";
import { videoApi } from "api";
import "./Uploader.scss";
import translate from "i18n/translate";

const Uploader = () => {
  const [videoFile, setVideoFile] = useState();
  const [userThumbnailUrl, setUserThubnailUrl] = useState("");
  const [userThumbnailFile, setUserThubnailFile] = useState(null);
  const [thumbnailUrls, setThumbnailUrls] = useState();
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState();
  const [thumbnailsLoading, setThumbnailsLoading] = useState(false);

  const [uploadProgressData, setUploadStartTime, setProgressEvent] = useUploadProgressData();

  const [video, setVideo] = useState("");
  const [videoName, setVideoName] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

  const navigate = useNavigate();

  const saveVideo = () => {
    if (video?._id) {
      const apiRequests = [];

      const fields = {
        name: videoName,
        description: videoDescription,
        selectedThumbnailIndex: selectedThumbnailIndex,
      };
      apiRequests.push(videoApi.update(video._id, fields));

      if (userThumbnailFile) {
        const files = [{ name: "thumb3", data: userThumbnailFile }];
        apiRequests.push(videoApi.uploadFiles(video._id, files));
      }

      Promise.all(apiRequests)
        .then((resArr) => navigate("/myvideo", { state: { video: resArr[resArr.length - 1] } }))
        .catch((err) => console.log(err.message));
    }
  };

  const createThumbnails = (videoFile, videoId) => {
    return new Promise((resolve, reject) => {
      setThumbnailsLoading(true);
      thumbnails.createAsBlobs(videoFile, 3).then((blobs) => {
        const files = [
          { name: "thumb0", data: blobs[0] },
          { name: "thumb1", data: blobs[1] },
          { name: "thumb2", data: blobs[2] },
        ];

        videoApi
          .uploadFiles(videoId, files)
          .then((video) => {
            setThumbnailUrls(video.thumbnails.fileNames);
            !selectedThumbnailIndex && setSelectedThumbnailIndex(video.thumbnails.selectedIndex);
            setThumbnailsLoading(false);
            resolve(video);
          })
          .catch((err) => {
            setThumbnailsLoading(false);
            reject(err);
          });
      });
    });
  };

  const uploadVideo = (videoFile) => {
    setUploadStartTime(Date.now());

    videoApi
      .getUrlForUpload(videoFile)
      .then((res) => res.data)
      .then(({ storageFileName, urlForUpload }) => {
        return videoApi
          .uploadFileToAWS(videoFile, urlForUpload, setProgressEvent)
          .then(() => videoApi.create({ name: path.parse(videoFile.name).base, fileName: storageFileName }))
          .then((video) => {
            setVideo(video);
            return createThumbnails(videoFile, video._id);
          })
          .then((video) => setVideo(video));
      })
      .then(() => setSaveButtonEnabled(true))
      .catch((err) => console.log(err.message));
  };

  const handleUserThumbnailChange = (file) => {
    setUserThubnailFile(file);
    setUserThubnailUrl(URL.createObjectURL(file));
  };

  return (
    <>
      <Header />
      <div className="uploader-wrapper">
        <MainLabel label={translate("uploader_mainLabel_label")} />
        <div className="uploader-content">
          <div className="col-left">
            <CardStyled className="upload-box">
              <div className="row-1">
                {!videoFile ? (
                  <div className="choose-label">{translate("uploader_cardStyled_chooseLabel")}</div>
                ) : (
                  <>
                    <div className="file-name">({videoFile?.name})</div>
                    <div className="file-uploadProgress">
                      {Number(uploadProgressData.loadedKb / 1000).toFixed(1)}Mb{" "}
                      {translate("uploader_cardStyled_fileUploadProgress_1")}{" "}
                      {Number(uploadProgressData.totalKb / 1000).toFixed(1)}Mb,{" "}
                      {translate("uploader_cardStyled_fileUploadProgress_2")}: {uploadProgressData.timeTotal},{" "}
                      {translate("uploader_cardStyled_fileUploadProgress_3")}: {uploadProgressData.timeLeft}
                    </div>

                    <div className="file-uploadProgress">
                      {translate("useUploadProgressData_uploadSpeed")}: {uploadProgressData.uploadSpeed}
                    </div>
                  </>
                )}
              </div>
              <div className="row-2">
                <Tooltip title={translate("uploader_tooltip_title")} placement="bottom" arrow>
                  <label disabled={Boolean(videoFile)} htmlFor="videofile-input">
                    <UploadIcon />
                    <input
                      id="videofile-input"
                      onChange={(e) => {
                        !videoName && setVideoName(e.target.files[0].name.split(".")[0]);
                        setVideoFile(e.target.files[0]);
                        uploadVideo(e.target.files[0]);
                      }}
                      type="file"
                      accept="video/*"
                    />
                  </label>
                </Tooltip>
              </div>
              {videoFile && (
                <div className="row-3">
                  <StatusBar percentage={uploadProgressData.percentage}></StatusBar>
                </div>
              )}
            </CardStyled>
          </div>
          <div className="col-right">
            <InputStyled
              label={translate("uploader_inputStyled_lable")}
              placeholder={translate("uploader_inputStyled_placeholder")}
              value={videoName}
              onChange={(e) => setVideoName(e.target.value)}
            />
            <CardStyled className="description-card">
              <textarea
                className="description-text"
                placeholder={translate("uploader_cardStyled_textarea_placeholder")}
                value={videoDescription}
                onChange={(e) => setVideoDescription(e.target.value)}
                name=""
                id=""
                cols="45"
              />
            </CardStyled>
            <InputStyled placeholder={translate("uploader_inputStyled_placeholder_2")} />
            <ThumbnailsBlock
              label={translate("uploader_thumbnailsBlock_title")}
              thumbnails={thumbnailUrls}
              selectedThumbnailIndex={selectedThumbnailIndex}
              userThumbnailUrl={userThumbnailUrl}
              isLoading={thumbnailsLoading}
              onUserThumbnailChange={handleUserThumbnailChange}
              onThumbnailSelect={setSelectedThumbnailIndex}
            />
          </div>
        </div>
        <Button className="save-btn" disabled={!saveButtonEnabled} onClick={saveVideo}>
          {translate("uploader_button_save")}
        </Button>
      </div>
    </>
  );
};

export default Uploader;
