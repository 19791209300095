import React, { useEffect, useState } from "react";
import CardStyled from "components/CardStyled/CardStyled";
import translate from "i18n/translate";

const UserThumbnail = ({ image, onChange, onClick, selected }) => {
  const [inputActive, setInputActive] = useState();

  useEffect(() => {
    setInputActive((image && selected) || !image);
  }, [selected, image]);

  return (
    <CardStyled
      className={selected ? "thumb-wrapper selected" : "thumb-wrapper"}
      onClick={(e) => {
        image && onClick && onClick(e);
        !inputActive && e.preventDefault();
      }}
    >
      <label htmlFor="file-input">
        {image ? (
          image && <img src={`${image}`} alt="" />
        ) : (
          <p className="selectImagelabel">{translate("editor_own_thumbnail")}</p>
        )}
        {inputActive && (
          <input
            id="file-input"
            onChange={(e) => {
              onChange && onChange(e.target.files[0]);
            }}
            type="file"
            accept="image/*"
          />
        )}
      </label>
    </CardStyled>
  );
};

export default UserThumbnail;
