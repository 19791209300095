import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { ReactComponent as RemoveIcon } from "assets/Icons/Remove.svg";
import { Button } from "@mui/material";
import { CardStyled, Header, InputStyled, MainLabel, ThumbnailsBlock } from "components";
import { AlertDialog } from "components";
import { videoApi } from "api";
import translate from "i18n/translate";
import "./Editor.scss";

const Editor = () => {
  const [video, setVideo] = useState("");
  const [userThumbnailUrl, setUserThubnailUrl] = useState("");
  const [userThumbnailFile, setUserThubnailFile] = useState(null);
  const [thumbnailUrls, setThumbnailUrls] = useState();
  const [selectedThumbnailIndex, setSelectedThumbnailIndex] = useState();
  const [videoName, setVideoName] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);

  const [alertDialogData, setAlertDialogData] = useState(null);

  const navigate = useNavigate();
  const params = useParams();
  const videoIdParam = useMemo(() => params.id, [params.id]);

  useEffect(() => {
    videoIdParam &&
      videoApi
        .getOne(videoIdParam)
        .then((res) => setVideo(res))
        .catch(() => setVideo(null));
  }, [videoIdParam]);

  useEffect(() => {
    video && setVideoName(video.name);
    video && setVideoDescription(video.description);
    video && video.thumbnails && setThumbnailUrls(video.thumbnails.fileNames);
    video && video.thumbnails && setSelectedThumbnailIndex(video.thumbnails.selectedIndex);
  }, [video]);

  useEffect(() => {
    setSaveButtonEnabled(
      selectedThumbnailIndex !== video?.thumbnails?.selectedIndex ||
        userThumbnailFile ||
        (video && videoName !== video.name) ||
        (video && videoDescription !== video.description)
    );
  }, [videoName, videoDescription, video, userThumbnailFile, selectedThumbnailIndex]);

  const openDeleteVideoDialog = () => {
    setAlertDialogData({
      onOk: () => {
        setAlertDialogData(null);
        videoApi
          .deleteOne(video._id)
          .then(() => navigate("/myvideo"))
          .catch((err) => console.log(err.message));
      },
      onCancel: () => setAlertDialogData(null),
      title: "editor_openDeleteVideoDialog_title",
      message: "editor_openDeleteVideoDialog_message",
    });
  };

  const saveVideo = () => {
    const apiRequests = [];

    const fields = {
      name: videoName,
      description: videoDescription,
      selectedThumbnailIndex: selectedThumbnailIndex ? selectedThumbnailIndex : 0,
    };
    apiRequests.push(videoApi.update(video._id, fields));

    if (userThumbnailFile) {
      const files = [{ name: "thumb3", data: userThumbnailFile }];
      apiRequests.push(videoApi.uploadFiles(video._id, files));
    }

    Promise.all(apiRequests)
      .then((resArr) => navigate("/myvideo", { state: { video: resArr[resArr.length - 1] } }))
      .catch((err) => console.log(err.message));
  };

  const handleUserThumbnailChange = (file) => {
    setUserThubnailFile(file);
    setUserThubnailUrl(URL.createObjectURL(file));
  };

  return (
    <>
      <Header />
      <div className="editor-wrapper">
        <MainLabel label={translate("editor_mainLabel_label")} />
        <div className="editor-content">
          <div className="col-left">
            <CardStyled className="upload-box">
              <div className="row-2">
                {video && (
                  <video
                    id="videoPlayer"
                    width="100%"
                    controls
                    muted="muted"
                    src={video.fileName}
                    type="video/*"
                  ></video>
                )}
              </div>
              <Button
                className="remove-btn"
                endIcon={<RemoveIcon />}
                onClick={openDeleteVideoDialog}
                title={translate("editor_button_removeVideo_title")}
              />
            </CardStyled>
          </div>
          <div className="col-right">
            <InputStyled
              label={translate("editor_inputStyled_label_video")}
              placeholder={translate("editor_inputStyled_placeholder_video")}
              value={videoName}
              onChange={(e) => setVideoName(e.target.value)}
            />
            <CardStyled className="description-card">
              <textarea
                className="description-text"
                placeholder={translate("editor_cardStyled_placeholder_video")}
                value={videoDescription}
                onChange={(e) => setVideoDescription(e.target.value)}
                cols="45"
              />
            </CardStyled>
            <InputStyled placeholder={translate("editor_inputStyled_placeholder_tags")} />

            <ThumbnailsBlock
              label={translate("editor_thumbnailsBlock_label")}
              thumbnails={thumbnailUrls}
              selectedThumbnailIndex={selectedThumbnailIndex}
              userThumbnailUrl={userThumbnailUrl}
              isLoading={false}
              onUserThumbnailChange={handleUserThumbnailChange}
              onThumbnailSelect={setSelectedThumbnailIndex}
            />
          </div>
        </div>
        <Button className="save-btn" disabled={!saveButtonEnabled} onClick={saveVideo}>
          {translate("editor_savebuttom")}
        </Button>
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};

export default Editor;
