import React, { useState, useRef, useEffect } from "react";
import "./Player.scss";
import { useAuthContext } from "auth";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as AllVideoBtn } from "assets/Icons/Upload.svg";
import { ReactComponent as EmptyAvatar } from "assets/Icons/Avatar.svg";
import { ReactComponent as PlayIcon } from "assets/Images/play.svg";
import { ReactComponent as ArrowIcon } from "assets/Icons/Arrow.svg";
import { ReactComponent as DescriptionIcon } from "assets/Icons/Description.svg";
import { ReactComponent as LinkIcon } from "assets/Icons/Link.svg";
import { ReactComponent as CopyIcon } from "assets/Icons/Copy.svg";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import translate from "i18n/translate";

const Player = ({ video, play, onPlay, channelIcon, channelName, channelLink }) => {
  const { user } = useAuthContext();
  const videoRef = useRef(null);
  const [progressBar, setProgressBar] = useState(0);
  const [descriptionBox, setDescriptionBox] = useState("");
  const handle = useFullScreenHandle();

  useEffect(() => {
    if (video) {
      if (play) {
        handle.enter();
        videoRef.current?.play();
      } else {
        handle.exit();
        videoRef.current?.pause();
      }
    }
  }, [video, play]);

  const handleFullScreenButton = () => {
    onPlay(!handle.active);
  };

  const handleFullScreenChange = (active) => {
    if (!active) {
      onPlay(false);
    }
  };

  const copyChannelLink = () => {
    const copyValue = document.getElementById("channel-link");
    const end = copyValue.value.length;
    copyValue.select();
    navigator.clipboard.writeText(copyValue.value);
    copyValue.setSelectionRange(end, end);
  };

  return (
    <>
      <div className="top_section_wrapper">
        <div className="creator_uploader">
          {user?.roles?.includes("creator") && (
            <Button
              className="creator_uploader_link"
              component={NavLink}
              to="/myvideo"
              endIcon={<AllVideoBtn />}
              title={translate("player_buttom_title_allVideo")}
            />
          )}
        </div>
      </div>
      <div className="video-player">
        <FullScreen handle={handle} onChange={handleFullScreenChange}>
          <video
            ref={videoRef}
            src={video?.fileName}
            type="video/*"
            loop
            playsInline
            onTimeUpdate={() => setProgressBar(videoRef.current.currentTime)}
          ></video>
          <div className={!(videoRef.current?.paused || progressBar === 0) ? "controllers" : "controllers active"}>
            <button className="fullscreenbtn" onClick={handleFullScreenButton} />
            <PlayIcon />
            {videoRef.current?.paused && (
              <input
                type="range"
                className="seek-bar"
                value={progressBar}
                step="0.1"
                min="0"
                max={String(videoRef.current?.duration)}
                readOnly
              />
            )}
            <div className="shade-overlay" />
          </div>
        </FullScreen>
      </div>
      <div className="name-n-description-box">
        <Tooltip
          title={
            !video?.description && !channelLink ? (
              ""
            ) : (
              <div
                className={`description-title ${!descriptionBox ? "arrow-rotate" : ""}`}
                onClick={(e) => setDescriptionBox(!descriptionBox)}
              >
                {translate("player_descriptionBox_Tooltip_title")} <ArrowIcon />
              </div>
            )
          }
          placement="right"
          arrow
        >
          <div className="channel-box">
            <div className="avatar">{!channelIcon ? <EmptyAvatar /> : <img src={`${channelIcon}`} alt="" />}</div>
            <div className="channel-name">{channelName}</div>
          </div>
        </Tooltip>
        <div className={`description-wrapper ${descriptionBox && "active"}`}>
          <div className="description">
            <span className={`description-style ${!channelLink ? "description-undefine" : ""}`}>
              <LinkIcon />
              <input id="channel-link" type="text" value={channelLink} />
              <Tooltip title={translate("player_copy_channel_link_Tooltip_title")} placement="top" arrow>
                <span className="copy-channel-link" onClick={copyChannelLink} title="">
                  <CopyIcon />
                </span>
              </Tooltip>
            </span>
            <span className={`description-style ${!video?.description ? "description-undefine" : ""}`}>
              <DescriptionIcon />
              <div className="description-content">{video?.description}</div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Player;
