const pathUtils = {
  parse: (fileName) => {
    let ext = fileName.split(".").pop();
    ext = ext === fileName ? "" : ext;
    let base = ext ? fileName.slice(0, fileName.indexOf("." + ext)) : fileName;
    return { ext, base };
  },
};

export default pathUtils;
