import React, { useEffect, useState } from "react";
import { Header, TagsList, TagTile } from "components";
import { useTagsList, useFilteredVideoList } from "hooks";

import "./TagsEditor.scss";

const TagsEditor = () => {
  const { tagsList, createTag, updateTag, deleteTag } = useTagsList();
  const [selectedTag, setSelectedTag] = useState();
  const { filteredVideoList: videoList } = useFilteredVideoList();

  useEffect(() => {
    selectedTag && setSelectedTag(tagsList.find((tag) => tag._id === selectedTag._id) || selectedTag);
  }, [tagsList, selectedTag]);

  const handleDeleteTag = (tag) => {
    deleteTag(tag._id).then(() => setSelectedTag(null));
  };

  const getDependentVideos = (tag) =>
    videoList
      ? videoList
        .map((element) => element)
        .filter((element) => element.tags.some((videoTag) => videoTag.tagId === tag?._id))
      : [];

  const getDependentTags = (tag) =>
    tagsList
      ? tagsList
        .map((element) => element)
        .filter((element) => element.dependencies.some((depTag) => depTag.tagId === tag?._id))
      : [];

  return (
    <>
      <Header />
      <div className="tagging-wrapper">
        <TagsList
          list={tagsList}
          selectedTag={selectedTag}
          onTagCreate={createTag}
          onTagSelect={(tag) => setSelectedTag(tag)}
        />
        <div className="main-block">
          {selectedTag && (
            <TagTile
              key={selectedTag._id}
              selected={true}
              onCreateTag={createTag}
              tag={selectedTag}
              onSave={updateTag}
              onDelete={handleDeleteTag}
              tagsList={tagsList}
              dependentVideos={getDependentVideos(selectedTag)}
              dependentTags={getDependentTags(selectedTag)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TagsEditor;
