import axios from "axios";

const videoApi = {
  getByUser: () => {
    return axios.get("/api/video").then((res) => res.data.videos);
  },

  getAll: () => {
    return axios.get("/api/video/all").then((res) => res.data.videos);
  },

  getOne: (id) => {
    return axios.get(`/api/video/${id}`).then((res) => res.data.video);
  },

  deleteOne: (id) => {
    return axios.delete(`/api/video/${id}`).then((res) => res.data.video);
  },
  create: (fields) => {
    return axios
      .post(`/api/video`, JSON.stringify(fields), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data.video);
  },

  update: (id, fields) => {
    return axios
      .put(`/api/video/${id}`, JSON.stringify(fields), { headers: { "Content-Type": "application/json" } })
      .then((res) => res.data.video);
  },

  uploadFiles: (id, files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append(file.name, file.data));
    return axios.put(`/api/video/${id}`, formData).then((res) => res.data.video);
  },

  getUrlForUpload: (videoFile) => {
    return axios.put("/api/video/urlforupload", JSON.stringify({ originalFileName: videoFile.name }), {
      headers: { "Content-Type": "application/json" },
    });
  },

  uploadFileToAWS: (videoFile, uploadUrl, onUploadProgress) => {
    return axios.put(uploadUrl, videoFile, {
      headers: {
        "x-amz-acl": "public-read",
        "Content-Type": videoFile.type,
      },
      onUploadProgress: onUploadProgress,
    });
  },

  convert: (id) => {
    return axios.put(`/api/video/convert/${id}`).then((res) => res.data.video);
  },
};

export default videoApi;
