import React, { useEffect } from "react";
import { useAuthContext } from "auth";
import translate from "i18n/translate";
import SigninAuthForm from "./SigninAuthForm";

const CreatorSignin = () => {
  const { error, setError, login, googleCreatorLogin } = useAuthContext();

  useEffect(() => {
    setError("");
  }, [setError]);

  return (
    <SigninAuthForm
      label={translate("creator_signin_signinAuthForm_label")}
      signupLink="/creator/signup"
      error={error}
      onSubmit={login}
      onGoogle={googleCreatorLogin}
    />
  );
};

export default CreatorSignin;
