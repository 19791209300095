import Thumbnail from "./Thumbnail";
import UserThumbnail from "./UserThumbnail";
import "./ThumbnailsBlock.scss";
import React from "react";

const ThumbnailsBlock = ({
  thumbnails,
  selectedThumbnailIndex,
  userThumbnailUrl,
  label,
  isLoading,
  onThumbnailSelect,
  onUserThumbnailChange,
}) => {

  const isSelected = (index)=>thumbnails && thumbnails[index] && thumbnails[index] === thumbnails[selectedThumbnailIndex]

  return (
    <div className="root">
      <p className="thumb-label">{label}</p>
      <div className="thumb-box">
        <Thumbnail
          onClick={() => onThumbnailSelect && onThumbnailSelect(0)}
          selected={isSelected(0)}
          isLoading={isLoading}
          image={thumbnails && thumbnails[0] ? `${thumbnails[0]}` : ""}
        />
        <Thumbnail
          onClick={() => onThumbnailSelect && onThumbnailSelect(1)}
          selected={isSelected(1)}
          isLoading={isLoading}
          image={thumbnails && thumbnails[1] ? `${thumbnails[1]}` : ""}
        />
        <Thumbnail
          onClick={() => onThumbnailSelect && onThumbnailSelect(2)}
          selected={isSelected(2)}
          isLoading={isLoading}
          image={thumbnails && thumbnails[2] ? `${thumbnails[2]}` : ""}
        />
        <UserThumbnail
          onClick={() => onThumbnailSelect && onThumbnailSelect(3)}
          selected={isSelected(3)}
          image={userThumbnailUrl ? userThumbnailUrl : thumbnails && thumbnails[3] ? `${thumbnails[3]}` : ""}
          onChange={(file) => onUserThumbnailChange && onUserThumbnailChange(file)}
        />
      </div>
    </div>
  );
};

export default ThumbnailsBlock;
