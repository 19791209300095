import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material//DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import translate from "i18n/translate";

const AlertDialog = ({ alertDialogData }) => {
  return (
    <div>
      {alertDialogData && (
        <Dialog
          open={Boolean(alertDialogData)}
          onClose={alertDialogData.onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{translate(alertDialogData.title)}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{translate(alertDialogData.message)}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={alertDialogData.onOk} color="primary">
              Ok
            </Button>
            <Button onClick={alertDialogData.onCancel} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default AlertDialog;