import React from "react";
import "./CardStyled.scss";

const CardStyled = ({ className, children, onMouseOver, onMouseLeave, onClick }) => {
  return (
    <div
      className={`card-wrapper ${className}`}
      onMouseOver={(e) => onMouseOver && onMouseOver(e)}
      onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
      onClick={(e) => onClick && onClick(e)}
    >
      {children}
    </div>
  );
};

export default CardStyled;
