import React, { useState } from "react";
import { Button } from "@mui/material";
import Checked from "assets/Icons/Checked.svg";
import "./Checkbox.scss";

const Checkbox = ({ label, onChange }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="checkbox-wrapper">
      <Button
        className={`checkbox ${toggle ? "active icon" : ""}`}
        onClick={() => {
          setToggle(!toggle);
          onChange && onChange(!toggle);
        }}
      >
        <img src={Checked} alt="" />
      </Button>
      <span className="auth-savepass-label">{label}</span>
    </div>
  );
};

export default Checkbox;
