import React, { useState, useContext } from "react";
import { SelectItem } from "components";
import { LocaleContext } from "i18n/LanguageProvider";
import { ReactComponent as IconGoogle } from "assets/Icons/Google.svg";
import Checkbox from "components/Checkbox/Checkbox";
import { Link } from "react-router-dom";
import { Button, TextField, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import { ReactComponent as IconAvatar } from "assets/Icons/Avatar.svg";
import { ReactComponent as IconLock } from "assets/Icons/Lock.svg";
import { useCredentialsValidation } from "hooks";
import translate from "i18n/translate";
import "../AuthPage.scss";

const SignupAuthForm = ({ signinLink, label, onSubmit, onGoogle, error }) => {
  const { supportedLocales, locale, onChangeLocale } = useContext(LocaleContext);

  const [licence, setLicence] = useState("");
  const [licenceAccepted, setLicenceAccepted] = useState(true);

  const { validationErrors, credentials, setCredential, validateCredentials, validationSuccessful } =
    useCredentialsValidation();

  const handleFormSubmit = () => {
    onSubmit(credentials);
  };

  const validateLicence = () => {
    setLicenceAccepted(licence);
    return licence;
  };

  const handleLicenseChange = (value) => {
    setLicence(value);
    value && setLicenceAccepted(true);
  };

  return (
    <div className="auth-page-wrapper">
      <div className="lang-switcher-style">
        <SelectItem
          value={locale.value}
          selectLabel={locale.name}
          onChange={onChangeLocale}
          menuItems={supportedLocales}
        />
      </div>

      <div className="auth-form">
        <p className="page-label">{label}</p>
        <Button
          className="big-button"
          onClick={() => onGoogle && validateLicence() && onGoogle()}
          startIcon={<IconGoogle />}
        >
          {translate("signUpAuthForm_button_registrationGoogle")}
        </Button>
        <div className="separator">{translate("signUpAuthForm_separator")}</div>

        <div className="text-field-box">
          <TextField
            autoFocus
            required
            name="email"
            type="email"
            onChange={(e) => setCredential("email", e.target.value)}
            onBlur={(e) => e.target.value && validateCredentials()}
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email}
            className="text-field"
            placeholder={translate("signUpAuthForm_textField_email_placeholder")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconAvatar />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            name="password"
            type="password"
            onChange={(e) => setCredential("password", e.target.value)}
            onBlur={(e) => e.target.value && validateCredentials()}
            error={Boolean(validationErrors.password)}
            helperText={validationErrors.password}
            className="text-field"
            placeholder={translate("signUpAuthForm_textField_password_placeholder")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconLock />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="check-forgot-wrapper">
          <Checkbox
            label={translate("signUpAuthForm_сheckbox_label")}
            onChange={(value) => setCredential("save", value)}
          />
        </div>

        <Button
          className="big-button"
          disabled={!validationSuccessful}
          onClick={() => validateLicence() && handleFormSubmit()}
        >
          {translate("signUpAuthForm_button_continue")}
        </Button>

        <div className="no-account">
          <span>{translate("signUpAuthForm_span_hasAccaunt")}</span>
          <Link to={signinLink} className="link">
            {translate("signUpAuthForm_link_enter")}
          </Link>
        </div>
        <div className="licence-link-wrapper">
          {translate("signUpAuthForm_licenceLink_title")}
          <Link to="/ua/privacy-policy"> {translate("signUpAuthForm_link_privacyPolicy")} </Link>
          {translate("signUpAuthForm_and")}
          <Link to="/ua/term-of-service">{translate("signUpAuthForm_link_termOfService")}</Link>
          <div className="ccby-licence">
            <Checkbox onChange={() => handleLicenseChange(!licence)} />
            <p className="ccby-licence-checkbox-title">{translate("signUpAuthForm_licence_checkbox_title")}</p>
            {!licenceAccepted && (
              <p className="licence-valiadation">{translate("signUpAuthForm_licence_valiadation")}</p>
            )}
          </div>
        </div>
        {error && <Alert severity="error">{translate(error)}</Alert>}
      </div>
      <Link className="link-licence-plus" to="/short-licence">
        {translate("signUpAuthForm_link_licence_plus")}
      </Link>
    </div>
  );
};

export default SignupAuthForm;
