import axios from "axios";
import config from "config";
import { createContext, useContext, useEffect, useRef, useState } from "react";

const AuthContext = createContext(null);

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const timeoutId = useRef();

  useEffect(() => {
    handleRefreshToken();
  }, []);

  useEffect(() => {
    timeoutId.current && clearTimeout(timeoutId.current);
    if (user) {
      timeoutId.current = setTimeout(handleRefreshToken, 15 * 1000 * 60);
    }
    return () => clearTimeout(timeoutId.current);
  }, [user]);

  const handleLogin = (credentials) => {
    axios
      .post("/api/auth/signin", JSON.stringify(credentials), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setUser(res.data);
        setError("");
      })
      .catch((res) => {
        let error;
        console.log(res.response.status);
        switch (res.response.status) {
          case 401:
            error = "authSignin_error401";
            break;
          default:
            error = "authSignin_error500";
            break;
        }
        setError(error);
      });
  };

  const handleSignup = (credentials) => {
    axios
      .post("/api/auth/signup", JSON.stringify(credentials), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setUser(res.data);
        setError("");
      })
      .catch((res) => {
        let error;
        switch (res.response.status) {
          case 403:
            error = "authSignup_error403";
            break;
          case 409:
            error = "authSignup_error409";
            break;
          default:
            error = "authSignup_error500";
            break;
        }
        setError(error);
      });
  };

  const handleSignupCreator = (credentials) => {
    axios
      .post("/api/auth/signupCreator", JSON.stringify(credentials), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setUser(res.data);
        setError("");
      })
      .catch((res) => {
        let error;
        switch (res.response.status) {
          case 403:
            error = "authSignup_error403";
            break;
          case 409:
            error = "authSignup_error409";
            break;
          default:
            error = "authSignup_error500";
            break;
        }
        setError(error);
      });
  };

  const handleLogout = () => {
    axios
      .get("/api/auth/logout")
      .then((res) => {
        setUser(null);
        setError("");
      })
      .catch((res) => {
        let error;
        switch (res.response.status) {
          default:
            error = "authLogout_error500";
            break;
        }
        setError(error);
      });
  };

  const handleRefreshToken = () => {
    setIsLoading(true);
    axios
      .get("/api/auth/refreshtokens")
      .then((res) => {
        setUser(res.data);
        setError("");
        setIsLoading(false);
      })
      .catch(() => {
        setUser(null);
        setError("");
        setIsLoading(false);
      });
  };

  const handleGoogleLogin = () => {
    window.open(`${config.serverDomain}/api/auth/google`, "_self");
  };

  const handleGoogleCreatorLogin = () => {
    window.open(`${config.serverDomain}/api/auth/googleCreator`, "_self");
  };

  const handleGetMe = () => {
    axios
      .get("/api/auth/getMe")
      .then((res) => {
        setUser(res.data);
        setError("");
      })
      .catch((res) => {
        let error;
        switch (res.response.status) {
          default:
            error = "authGetMe_error500";
            break;
        }
        setError(error);
        setUser(null);
      });
  };

  const value = {
    user,
    error,
    setError,
    isLoading,
    login: handleLogin,
    logout: handleLogout,
    signup: handleSignup,
    signupCreator: handleSignupCreator,
    refreshToken: handleRefreshToken,
    googleLogin: handleGoogleLogin,
    googleCreatorLogin: handleGoogleCreatorLogin,
    getMe: handleGetMe,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
