import React from 'react';
import './InputStyled.scss';

const InputStyled = ({ className, type, placeholder, label, value, onChange }) => {
  return (
    <>
      <div className="input-label">{label}</div>
      <input type={type} placeholder={placeholder} value={ value} onChange={onChange} className={`input-wrapper ${className}`} />
    </>
  );
};

export default InputStyled;
