import React from "react";
import { ReactComponent as EmptyIcon } from "assets/Icons/NoImage.svg";
import "./SavedVideo.scss";

const SavedVideo = ({ video, onEdit, onDelete }) => {
  const thumbnailUrl =
    video?.thumbnails?.selectedIndex >= 0 ? `${video.thumbnails.fileNames[video.thumbnails.selectedIndex]}` : "";

  return (
    <div className="video-wrapper" onClick={() => onEdit && onEdit(video)}>
      <div className="video-thumb">{!thumbnailUrl ? <EmptyIcon /> : <img src={thumbnailUrl} alt="" />}</div>
      <div className="video-description">
        <div className="col-1">
          <div className="row-1"></div>
          <div className="row-2">{video?.name}</div>
          <div className="row-3">{video?.description}</div>
          <div className="row-4">{video?.date}</div>
        </div>
      </div>
    </div>
  );
};

export default SavedVideo;
