import "./policy.scss";
import { NavLink } from "react-router-dom";

const UaTermOfService = () => {
  return (
    <div className="article-wrapper">
      <div className="article-body">
        <div className="article-title">Угода з користувачем</div>
        <div className="article-date">Оновлено 13.05.2022</div>
        <br />
        <div className="article">
          Ця Угода з користувачем (далі - Угода) встановлює умови та правила використання сайту{" "}
          <NavLink to="/"> https://bulbee.tv</NavLink> (далі - Bulbee), у тому числі:
        </div>
        <ul className="article-list">
          <li>умови використання розміщених на сайті матеріалів;</li>
          <li>умови реєстрації;</li>
          <li>
            умови користування Персональним кабінетом, відправлення повідомлень із сайту, розміщення пропозицій щодо
            покращення якості сервісів.
          </li>
        </ul>
        <div className="article-subtitle">1. Загальні умови</div>
        <div className="article">
          1.1. Використання сайту означає, що користувач приймає і зобов'язується дотримуватися всіх нижченаведених умов
          цієї Угоди.
        </div>
        <div className="article">
          1.2. Ця Угода повністю чи в будь-якій її частині може бути змінена власником ресурсу в будь-який час. Нова
          редакція Угоди вступає в силу з моменту її опублікування на сайті.
        </div>

        <div className="article-subtitle">2. Взаємодія з сайтом, реєстрація</div>
        <div className="article">
          2.1. Не допускаються підбір пароля, злом або інші дії з метою отримання доступу до чужих логінів і паролів, а
          також використання Особистого кабінету іншого користувача.
        </div>
        <div className="article">
          2.2. При використанні Сайту та інформації з Сайту заборонені наступні дії:
          <ul className="article-list">
            <li>використовувати автоматизовані програми взаємодії з Bulbee і його сервісами;</li>
            <li>
              використовувати Bulbee будь-яким способом, який може перешкоджати нормальному функціонуванню Bulbee та
              його сервісів;
            </li>
            <li>
              використовувати посилання на сторінки Bulbee або на сам Bulbee в спам-розсилках (масова розсилка реклами
              без згоди одержувача).
            </li>
          </ul>
        </div>

        <div className="article-subtitle">3. Умови використання матеріалів</div>
        <div className="article">
          3.1. Сайт містить матеріали, товарні знаки та інші охоронювані законом матеріали, у тому числі тексти,
          фотографії, відеоматеріали, графічні зображення, аудіофайли.
        </div>
        <div className="article">
          3.2. Усі права на використання змісту сайту, крім випадків, зазначених у змісті опублікованих на сайті
          матеріалів, належать приватному підприємецю Наталії Колчевій.
        </div>
        <div className="article">
          3.3. Користувач може завантажувати з сайту матеріали тільки для особистого використання.
        </div>
        <div className="article">
          3.4. Дозволено використання інформації засобами масової інформації за умови дотримання умов цієї Угоди а також
          умов, які передбачені на сторінках, де розміщений контент. При цьому посилання на Bulbee, а для інтернет ЗМІ -
          гіперпосилання на <NavLink to="/"> https://bulbee.tv</NavLink> обов'язкове.
        </div>
        <div className="article">
          3.5. Вся інформація, розміщена на Bulbee, включно із графічною, є власністю Товариства та/або його партнерів,
          якщо прямо не зазначено інше.
        </div>
      </div>
    </div>
  );
};

export default UaTermOfService;
