import { useAuthContext } from "auth";
import { Navigate, Outlet, useLocation } from "react-router";

const AuthRoute = () => {
  const { user, isLoading } = useAuthContext();
  const location = useLocation();

   if (isLoading) {
     return null;
   }

  if (user) {
    return <Navigate to={location.state?.from ? location.state.from : "/uploader"} state={{}} />;
  }

  return <Outlet />;
};

export default AuthRoute;
