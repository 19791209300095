import "./policy.scss";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";
import translate from "i18n/translate";

const FullLicence = () => {
  return (
    <div className="article-wrapper">
      <div className="article-body">
        <div className="article-title">{translate("uaFullLicence_title")}</div>
        <div className="article-date">{translate("uaLicence_articleDate")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_1")}</div>
        <div className="article">{translate("uaFullLicence_article_1")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_2")}</div>
        <div className="article">{translate("uaFullLicence_article_2")}</div>
        <div className="article">{translate("uaFullLicence_article_3")}</div>
        <div className="article">{translate("uaFullLicence_article_4")}</div>
        <div className="article">{translate("uaFullLicence_article_5")}</div>
        <div className="article">{translate("uaFullLicence_article_6")}</div>
        <div className="article">{translate("uaFullLicence_article_7")}</div>
        <div className="article">{translate("uaFullLicence_article_8")}</div>
        <div className="article">{translate("uaFullLicence_article_9")}</div>
        <div className="article">{translate("uaFullLicence_article_10")}</div>
        <div className="article">{translate("uaFullLicence_article_11")}</div>
        <div className="article">{translate("uaFullLicence_article_12")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_3")}</div>
        <div className="article">{translate("uaFullLicence_article_13")}</div>
        <div className="article">{translate("uaFullLicence_article_14")}</div>
        <div className="article">{translate("uaFullLicence_article_15")}</div>
        <div className="article">{translate("uaFullLicence_article_16")}</div>
        <div className="article">{translate("uaFullLicence_article_17")}</div>
        <div className="article">{translate("uaFullLicence_article_18")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_4")}</div>
        <div className="article">{translate("uaFullLicence_article_19")}</div>
        <ul className="main-list">
          <li>
            {translate("uaFullLicence_list_1")}
            <ul className="sub-list">
              <li>{translate("uaFullLicence_sub_list_1")}</li>
              <li>{translate("uaFullLicence_sub_list_2")}</li>
            </ul>
          </li>
          <li>{translate("uaFullLicence_list_2")}</li>
          <li>{translate("uaFullLicence_list_3")}</li>
          <li>{translate("uaFullLicence_list_4")}</li>
          <li>{translate("uaFullLicence_list_5")}</li>
          <li>{translate("uaFullLicence_list_6")}</li>
        </ul>
        <div className="article">{translate("uaFullLicence_article_20")}</div>
        <div className="article">{translate("uaFullLicence_article_21")}</div>
        <div className="article">{translate("uaFullLicence_article_22")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_5")}</div>
        <div className="article">{translate("uaFullLicence_article_23")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_6")}</div>
        <div className="article">{translate("uaFullLicence_article_24")}</div>
        <ul className="main-list">
          <li>{translate("uaFullLicence_list_7")}</li>
          <li>{translate("uaFullLicence_list_8")}</li>
        </ul>
        <div className="article">{translate("uaFullLicence_article_25")}</div>
        <div className="article">{translate("uaFullLicence_article_26")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_7")}</div>
        <div className="article">{translate("uaFullLicence_article_27")}</div>
        <div className="article-subtitle">{translate("uaFullLicence_subtitle_8")}</div>
        <div className="article">{translate("uaFullLicence_article_28")}</div>
        <div className="article">{translate("uaFullLicence_article_29")}</div>
        <div className="article">{translate("uaFullLicence_article_30")}</div>
        <div className="article">{translate("uaFullLicence_article_31")}</div>
      </div>
    </div>
  );
};

export default FullLicence;
