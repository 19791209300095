import { useEffect, useState } from "react";
import { videoApi, usersApi } from "api";

const useFilteredVideoList = () => {
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filter, setFilter] = useState({ tags: [], untagged: false });
  const [creators, setCreators] = useState([]);
  const [selectedCreatorId, setSelectedCreatorId] = useState();

  useEffect(() => {
    if (list) {
      const newCreators = [];
      list.forEach((video) => {
        !newCreators.some((creator) => creator._id === video.user._id) &&
          newCreators.push({
            _id: video.userId,
            channelName: video.user.profile.channelName || video.user.email,
            channelIcon: video.user.profile.channelIcon || video.user.profile.picture,
          });
      });
      setCreators(newCreators);
    }
  }, [list]);

  useEffect(() => {
    if (list) {
      const { tags, untagged } = filter;
      if (untagged) {
        setFilteredList(
          list.filter((video) => !video?.tags?.length && (!selectedCreatorId || video.userId === selectedCreatorId))
        );
      } else {
        setFilteredList(
          list.filter(
            (video) =>
              video?.tags?.length &&
              !tags.some((tag) => !video.tags.find((videoTag) => videoTag.tagId === tag._id)) &&
              (!selectedCreatorId || video.userId === selectedCreatorId)
          )
        );
      }
    }
  }, [list, filter, selectedCreatorId]);

  const getList = async () => {
    try {
      const videos = await videoApi.getAll();
      const users = await usersApi.getAll();
      videos.forEach((video) => (video.user = users.find((user) => user._id === video.userId)));
      setList(videos);
    } catch (error) {
      setList([]);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const updateVideo = (videoId, fields) => {
    videoApi.update(videoId, fields).then(getList);
  };


  return {
    creators,
    selectCreator: setSelectedCreatorId,
    filteredVideoList: filteredList,
    tagsFilter: filter,
    applyTagsFilter: setFilter,
    updateVideo ,
    reloadVideoList: getList};
};

export default useFilteredVideoList;
