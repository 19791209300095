import React, { useState, useEffect } from "react";
import { Header, MainLabel, UserCard } from "components";
import { AlertDialog } from "components";
import "./Admin.scss";
import translate from "i18n/translate";
import { usersApi } from "api";

const Admin = () => {
  const [userList, setUserList] = useState([]);
  const [alertDialogData, setAlertDialogData] = useState(null);

  const getUserList = () => {
    usersApi
      .getAll()
      .then((users) => {
        users ? setUserList(users) : setUserList([]);
      })
      .catch((error) => {
        setUserList([]);
        console.log(error);
      });
  };

  const deleteUser = (user) => {
    usersApi
      .deleteOne(user._id)
      .then((deletedUser) => {
        if (deletedUser) getUserList();
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUserList();
  }, []);

  const openDeleteUserDialog = (user) => {
    setAlertDialogData({
      onOk: () => {
        deleteUser(user);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "admin_openDeleteUserDialog_title",
      message: "admin_openDeleteUserDialog_message",
    });
  };

  const updateUser = (user, newUserRoles) => {
    usersApi
      .update(user._id, { roles: newUserRoles })
      .then((updatedUser) => {
        if (updatedUser) getUserList();
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Header />
      <div className="all-users-wrapper">
        <MainLabel label={translate("admin_MainLabel_label")} />
        {userList &&
          userList.map((user) => (
            <div className="all-users-box" key={user._id}>
              <UserCard user={user} updateUserRole={updateUser} onDelete={openDeleteUserDialog} />
            </div>
          ))}
        <AlertDialog alertDialogData={alertDialogData} />
      </div>
    </>
  );
};

export default Admin;
