import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Tag from "./Tag";
import "./TagsListWithFilter.scss";

const TagsList = ({ list, filter, onTagFilterChange }) => {
  const [searchString, setSearchString] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    searchString && setFilteredList(list.filter((tag) => tag.name?.includes(searchString)));
    !searchString && setFilteredList(list);
  }, [list, searchString]);

  const handleTagClick = (tag) => {
    const { tags } = filter;
    const tagIndex = tags.findIndex((element) => element === tag);
    if (tagIndex < 0) {
      tags.push(tag);
    } else {
      tags.splice(tagIndex, 1);
    }
    tags.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    onTagFilterChange({ tags: tags, untagged: filter.untagged });
  };

  const handleUntaggedChange = (chacked) => {
    onTagFilterChange({ tags: filter.tags, untagged: !chacked });
  };

  return (
    <>
      <div className="filtered-tag-list">
        <div className="search-box">
          <input
            className="search-input"
            type="text"
            placeholder="Search"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <div className="tag-switcher-wrapper">
          <div className="tag-switcher">
            <Switch checked={!filter.untagged} onChange={(e) => handleUntaggedChange(e.target.checked)} />С тегами
          </div>
          <div className="tag-filter-box">
            <div className="filter-label">Tags:</div>
            <div className="filtered-tag-box">
              {filter.tags.map((tag, index) => (
                <Tag key={index} tag={tag} selected={true} onClick={handleTagClick} />
              ))}
              {filteredList.map(
                (tag, index) =>
                  !filter.tags.includes(tag) && <Tag key={index} tag={tag} selected={false} onClick={handleTagClick} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsList;
