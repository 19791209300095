import React, { useState, useEffect, createContext } from "react";
import { IntlProvider } from "react-intl";
import translations from "./translations";

const supportedLocales = [
  { value: "ru", name: "Руc" },
  { value: "ua", name: "Укр" },
];

const LocaleContext = createContext({});

const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(supportedLocales[1]);

  const initialSetLocale = () => {
    const userLocale = JSON.parse(localStorage.getItem("userLocale"));
    if (userLocale) {
      setLocale(userLocale);
    } else {
      handleChangeLocale("ua");
    }
  };

  const getBrowserSupportedLocale = () => {
    return supportedLocales.find((item) => item.value === window.navigator.language.split(/[-_]/)[0]);
  };

  const handleChangeLocale = (value) => {
    const userLocale = supportedLocales.find((item) => item.value === value);
    setLocale(userLocale);
    localStorage.setItem("userLocale", JSON.stringify(userLocale));
  };

  useEffect(() => {
    initialSetLocale();
  }, []);

  return (
    <LocaleContext.Provider value={{ supportedLocales, locale, onChangeLocale: handleChangeLocale }}>
      <IntlProvider locale={locale.value} messages={translations[locale.value]}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export { LanguageProvider, LocaleContext };
