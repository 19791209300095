import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const Switcher = ({ label, role, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={role} onChange={onChange ? onChange : null} color="warning" />}
        label={`${label}`}
      />
    </FormGroup>
  );
};
export default Switcher;