import { useState } from "react";

const useCredentialsValidation = () => {
  const [errors, setErrors] = useState({});
  const [credentials, setCredentials] = useState({});
  const [validationSuccessful, setValidationSuccessful] = useState(false);

  const setCredential = (credentialType, value) => {
    setCredentials({ ...credentials, [credentialType]: value });
    
    
    switch (credentialType) {
      case "password":
        setErrors({ ...errors, password: "", passwordRepeat: "" });
        break;

      default:
        setErrors({ ...errors, [credentialType]: "" });
        break;
    }
  };

  const validateCredentials = () => {
    const errors = {};
    let result = true;
    if (typeof credentials.email === "undefined") {
      errors.email = "";
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Не правильный email адрес";
      result = false;
    }
    if (typeof credentials.password === "undefined") {
      errors.password = "";
    } else if (credentials.password.length < 8) {
      errors.password = "Длина пароля меньше 8 символов ";
      result = false;
    }

    if (typeof credentials.passwordRepeat === "undefined" || errors.password) {
      errors.passwordRepeat = "";
    } else if (credentials.passwordRepeat !== credentials.password) {
      errors.passwordRepeat = "Пароли не эквивалентные";
      result = false;
    }

    setErrors(errors);
    setValidationSuccessful(result);
  };

  return { validationErrors: errors, credentials, setCredential, validateCredentials, validationSuccessful };
};

export default useCredentialsValidation;
