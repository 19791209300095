import React from "react";
import { Routes, Route } from "react-router-dom";
import CreatorSignup from "pages/AuthPages/Signup/CreatorSignup";
import CreatorSignin from "pages/AuthPages/Signin/CreatorSignin";
import Uploader from "pages/Uploader/Uploader";
import Editor from "pages/Editor/Editor";
import MyVideo from "pages/MyVideo/MyVideo";
import VideoPlayer from "pages/VideoPlayer/VideoPlayer";
import Tagging from "pages/Tagging/Tagging";
import TagsEditor from "pages/TagsEditor/TagsEditor";
import UaPrivacyPolicy from "pages/Policy/UaPrivacyPolicy";
import UaTermOfService from "pages/Policy/UaTermOfService";
import ShortLicence from "pages/Policy/ShortLicence";
import FullLicence from "pages/Policy/FullLicence";
import EditAccount from "pages/EditAccount/EditAccount";
import PasswordForgot from "pages/AuthPages/ForgotPassword";
import NewPassword from "pages/AuthPages/NewPassword";
import { ProtectedRoute, AuthRoute, AuthProvider } from "auth";
import { ROLE_ADMIN, ROLE_CREATOR } from "constants";
import Admin from "pages/Admin/Admin";
import translate from "i18n/translate";
import "./styles/index.scss";


function App() {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route index element={<VideoPlayer />} />
          <Route path="/" element={<VideoPlayer />} />
          <Route path="/ua/privacy-policy" element={<UaPrivacyPolicy />} />
          <Route path="/ua/term-of-service" element={<UaTermOfService />} />
          <Route path="/short-licence" element={<ShortLicence />} />
          <Route path="/full-licence" element={<FullLicence />} />

          <Route path="/password/forgot" element={<PasswordForgot />} />
          <Route path="/password/new/:token" element={<NewPassword />} />

          <Route element={<AuthRoute />}>
            <Route path="/creator/signup" element={<CreatorSignup />} />
            <Route path="/creator/signin" element={<CreatorSignin />} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={[ROLE_CREATOR, ROLE_ADMIN]} />}>
            <Route path="/myvideo" element={<MyVideo />} />
            <Route path="/uploader" element={<Uploader />} />
            <Route path="/editor/:id" element={<Editor />} />
            <Route path="/edit_account" element={<EditAccount />} />
          </Route>

          <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN]} />}>
            <Route path="/admin" element={<Admin />} />
            <Route path="/tagging" element={<Tagging />} />
            <Route path="/tagseditor" element={<TagsEditor />} />
          </Route>

          <Route
            path="/noAccess"
            element={<h2 style={{ textAlign: "center" }}>{translate("app_route_noAccess_message")}</h2>}
          />
          <Route
            path="*"
            element={<h2 style={{ textAlign: "center" }}>{translate("app_route_pageMissing_message")}</h2>}
          />
        </Routes>
      </AuthProvider>
    </>
  );
}

export default App;
