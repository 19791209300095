import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TagEdit from "./TagEdit/TagEdit";
import Tag from "./Tag/Tag";
import InputWithPopup from "../InputWithPopup/InputWithPopup";
import "./VideoTile.scss";

const VideoTile = ({ video, tagsList, onCreateTag, selected, onClick, onSave, onConvertClick }) => {
  const [videoTags, setVideoTags] = useState([]);
  const [usedTagsList, setUsedTagsList] = useState([]);
  const needSaveRef = useRef(false);

  const [channelIcon, setChannelIcon] = useState("");
  const [channelName, setChannelName] = useState("");

  const [selectedVideoSource, setSelectedVideoSource] = React.useState();
  const [videoSources, setVideoSources] = React.useState();
  const [convertingStatus, setConvertingStatus] = useState({
    lastProgressCode: "",
    lastProgressMessage: "",
    inProgress: false,
  });

  const handelVideoSourceChange = (event) => {
    setSelectedVideoSource(event.target.value);
  };

  const handleConvertClick = () => {
    setConvertingStatus({ ...convertingStatus, inProgress: true });
    onConvertClick(video._id);
  };

  useEffect(() => {
    if (video) {
      setChannelIcon(video?.user?.profile?.channelIcon || video?.user?.profile?.picture);
      setChannelName(video?.user?.profile?.channelName || video?.user?.email);

      const originalSource = { shortSize: "original", fileName: video.fileName };
      const newVideoSources = [originalSource, ...video.sources];
      setVideoSources(newVideoSources);

      const newSelectedVideoSource = newVideoSources.find(
        (videoSource) => videoSource.shortSize === selectedVideoSource?.shortSize
      );
      setSelectedVideoSource(newSelectedVideoSource || originalSource);

      setConvertingStatus(video.convertingStatus);
    }
  }, [video]);

  useEffect(() => {
    video && setVideoTags(video.tags);
  }, [video]);

  useEffect(() => {
    if (!selected && needSaveRef.current) {
      onSave(video._id, { tags: videoTags });
      needSaveRef.current = false;
    }
  }, [selected, onSave, video._id, videoTags]);

  useEffect(() => {
    setUsedTagsList(
      videoTags.map((videoTag) => {
        return { ...tagsList.find((tag) => tag._id === videoTag.tagId) };
      })
    );
  }, [videoTags, tagsList]);

  const getTagNameById = (tagId) => tagsList.find((tag) => tag._id === tagId)?.name;

  const handleVideoTagCreateAndAdd = (tagName) => {
    onCreateTag(tagName).then((tag) => handleVideoTagAdd(tag));
  };

  const handleVideoTagAdd = (tag) => {
    if (tag) {
      const videoTag = { tagId: tag._id, weight: 0, weightAutoCalc: true };
      videoTags.push(videoTag);
      recalculateVideoTagsWeight();
      setVideoTags([...videoTags]);
      needSaveRef.current = true;
    }
  };

  const handleVideoTagDelete = (videoTag) => {
    const index = videoTags.indexOf(videoTag);
    videoTags.splice(index, 1);
    recalculateVideoTagsWeight();
    setVideoTags([...videoTags]);
    needSaveRef.current = true;
  };

  const recalculateVideoTagsWeight = () => {
    const autoCalcTags = videoTags.filter((videoTag) => videoTag.weightAutoCalc);
    let availableAutocalcSum = getAvailableAutocalcSum();
    autoCalcTags.forEach((videoTag, index) => {
      videoTag.weight = Math.floor(availableAutocalcSum / (autoCalcTags.length - index));
      availableAutocalcSum = availableAutocalcSum - videoTag.weight;
    });
  };

  const getAvailableAutocalcSum = () => {
    let noneAutocalcSum = 0;
    videoTags.forEach((videoTag) => {
      if (!videoTag.weightAutoCalc) {
        noneAutocalcSum = noneAutocalcSum + videoTag.weight;
      }
    });
    return Math.floor(100 - noneAutocalcSum);
  };

  const getAvailableWeightValueFor = (vTag) => {
    let noneAutocalcSum = 0;
    videoTags.forEach((videoTag) => {
      if (!videoTag.weightAutoCalc && videoTag !== vTag) noneAutocalcSum = noneAutocalcSum + videoTag.weight;
    });
    return Math.floor(100 - noneAutocalcSum);
  };

  const handleWeightChange = (videoTag, newWeight) => {
    const videoTagIndex = videoTags.indexOf(videoTag);
    if (getAvailableWeightValueFor(videoTag) < Number(newWeight)) {
      videoTags[videoTagIndex] = { ...videoTag };
    } else {
      videoTags[videoTagIndex] = { ...videoTag, weight: Number(newWeight), weightAutoCalc: false };
      recalculateVideoTagsWeight();
      needSaveRef.current = true;
    }
    setVideoTags([...videoTags]);
  };

  const availableColors = ["#aee3d8", "#d1caae", "#e7e1f9", "#cfdaff", "#cffcd9", "#faf8cd"];

  function* getColorIterator(allColors) {
    let i = 0;
    while (true) {
      yield allColors[i];
      i = (i + 1) % allColors.length;
    }
  }
  let colorIterator = getColorIterator(availableColors);

  return (
    <div className={`video-tile-wrapper ${selected ? "video-tile-wrapper-active" : ""}`}>
      <div className="tile-container" onClick={(e) => onClick && onClick(video)}>
        {selected && (
          <div className="tile-video">
            <video
              id="videoPlayer"
              height="100%"
              controls
              muted="muted"
              src={selectedVideoSource?.fileName}
              type="video/*"
            ></video>
          </div>
        )}
        {!selected && (
          <img className="tile-img" src={video.thumbnails.fileNames[video.thumbnails.selectedIndex]} alt="" />
        )}
        <div className="content-wrapper">
          <div className={`tile-video-name ${selected ? "tile-video-name-active" : ""}`}>{video?.name}</div>
          <div className="content-right-side">
            <div className="user-wrapper">
              <div className="user-channel-name">{channelName}</div>
              <div className="user-avatar">
                <img src={channelIcon} alt="avatar" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-tags">
        <div className="none-active-tag">
          <div className={`tag-label ${selected ? "tag-label-active" : ""}`}>
            {!selected &&
              videoTags.map((videoTag, i) => (
                <Tag
                  key={i}
                  style={{ backgroundColor: `${colorIterator.next().value}`, width: `${videoTag.weight}%` }}
                  videoTag={videoTag}
                  tagName={getTagNameById(videoTag.tagId)}
                />
              ))}

            {selected && (
              <>
                {videoTags.map((videoTag, i) => (
                  <TagEdit
                    active={true}
                    key={i}
                    videoTag={videoTag}
                    tagName={getTagNameById(videoTag.tagId)}
                    onWeightChange={handleWeightChange}
                    onDelete={handleVideoTagDelete}
                  />
                ))}
                <InputWithPopup
                  rows={tagsList}
                  usedRows={usedTagsList}
                  onSelect={handleVideoTagAdd}
                  onCreate={handleVideoTagCreateAndAdd}
                  placeholder="Добавить тег"
                />
              </>
            )}
          </div>
        </div>
        {selected && (
          <div className="video-resolution-select">
            <button disabled={convertingStatus.inProgress} onClick={handleConvertClick}>
              {videoSources?.length > 1 ? "Reconvert" : "Convert"}
            </button>
            <Select
              value={selectedVideoSource}
              onChange={handelVideoSourceChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {videoSources &&
                videoSources.map((videoSource) => <MenuItem value={videoSource}>{videoSource.shortSize}</MenuItem>)}
            </Select>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoTile;
