import { useEffect, useState } from "react";
import { tagsApi } from "api";

const useTagsList = () => {
  const [tagsList, setTagsList] = useState([]);

  const getTagNameById = (tagId) => {
    return tagsList.find((tag) => tag._id === tagId)?.name;
  };

  const getList = () => {
    tagsApi
      .getAll()
      .then((list) => {
        setTagsList(
          list.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }).map(tag => { tag.name = tag.name || "noName"; return tag })
        );
      })
      .catch(() => setTagsList([]));
  };

  useEffect(() => {
    getList();
  }, []);

  const createTag = (tagName) => {
    return tagsApi
      .create({ name: tagName })
      .then((tag) => {
        getList();
        return tag;
      })
      .catch(() => null);
  };

  const updateTag = (tagId, fields) => {
    return tagsApi
      .update(tagId, fields)
      .then((tag) => {
        getList();
        return tag;
      })
      .catch(() => null);
  };

  const deleteTag = (tagId) => {
    return tagsApi
      .deleteOne(tagId)
      .then((tag) => {
        getList();
        return tag;
      })
      .catch(() => null);
  };

  return { tagsList, getTagNameById, createTag, updateTag, deleteTag, reloadTagList: getList };
};
export default useTagsList;
