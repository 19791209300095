import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const SelectItem = ({ value, inputLabel, selectLabel, onChange, menuItems }) => {
  
  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={selectLabel}
          onChange={(event) => onChange(event.target.value)}>
          {menuItems.map((item) => <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectItem;