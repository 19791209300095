import React, { useEffect, useState } from "react";
import Tag from "./Tag";
import "./TagsList.scss";

const TagsList = ({ list, selectedTag, onTagSelect, onTagCreate }) => {
  const [searchString, setSearchString] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    if (searchString) {
      const newFilteredList = list.filter((tag) => tag.name?.includes(searchString));
      if (newFilteredList.length) {
        setFilteredList(newFilteredList);
      } else {
        setFilteredList([{ name: "Создать: " + searchString }]);
      }
    }

    !searchString && setFilteredList(list);
  }, [list, searchString]);

  const handleTagClick = (tag) => {
    if (tag._id) {
      onTagSelect(tag);
    } else {
      onTagCreate(searchString).then((createdTag) => {
        onTagSelect(createdTag)
      });
    }
  };

  return (
    <>
      <div className="tag-list">
        <div className="search-box">
          <input
            className="search-input"
            type="text"
            placeholder="Search"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <div className="tag-switcher-wrapper">
          <div className="tag-filter-box">
            <div className="filter-label">Tags:</div>
            <div className="filtered-tag-box">
              {filteredList.map((tag, index) => (
                <Tag key={index} tag={tag} selected={tag._id === selectedTag?._id} onClick={handleTagClick} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsList;
