import axios from "axios";
import { useState } from "react";
import translate from "../i18n/translate";

const usePasswordRecovery = () => {
  const [status, setStatus] = useState({ error: "", success: false });

  const sendResetPasswordRequest = (email, locale) => {
    axios
      .put("/api/auth/resetPasswordRequest", { email, locale }, { headers: { "Content-Type": "application/json" } })
      .then(() => {
        setStatus({ error: "", success: true });
      })
      .catch(() => {
        let error = translate("passwordRecovery_commonError");
        setStatus({
          error: error,
          success: false,
        });
      });
  };

  const resetPassword = (token, password, locale) => {
    axios
      .put("/api/auth/resetPassword", { token, password, locale }, { headers: { "Content-Type": "application/json" } })
      .then(() => {
        setStatus({ error: "", success: true });
      })
      .catch((res) => {
        let error;
        switch (res.response.status) {
          case 403:
            error = translate("passwordRecovery_resetPassword_error403");
            break;
          default:
            error = translate("passwordRecovery_commonError");
            break;
        }

        setStatus({
          error: error,
          success: false,
        });
      });
  };

  return { status, sendResetPasswordRequest, resetPassword };
};

export default usePasswordRecovery;
