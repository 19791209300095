import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const InputWithPopup = ({ rows = [], usedRows = [], onSelect, onCreate, resetInputOnSelect = true, placeholder = "", nothingToChooseText = "", width = 200, variant = "standard" }) => {
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const autocompleteOptions = rows.filter((row) => !usedRows.some((usedRow) => usedRow._id === row._id));

  const handleGetOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.name;
  };
  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;

    if (filtered.length > 10) {
      filtered.splice(10, filtered.length - 10);
    }

    const isUsed = usedRows.some((row) => inputValue === row.name);
    const isExisting = options.some((option) => inputValue === option.name);

    if (onCreate && (inputValue !== "" && !isExisting && !isUsed)) {
      filtered.push({
        inputValue,
        name: `Создать "${inputValue}"`,
      });
    }

    return filtered;
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === "string") {
      setValue({
        name: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      resetInputOnSelect && setInputValue("");
      onCreate && onCreate(newValue.inputValue);
    } else {
      resetInputOnSelect && setInputValue("");
      onSelect && onSelect(newValue);
    }
  };

  const handleInputChange = (event, newValue, reason) => {
    setInputValue(newValue);
  };

  return (
    <Autocomplete
      id="tag-adding"
      autoHighlight
      freeSolo={true}
      options={autocompleteOptions}
      noOptionsText={nothingToChooseText}
      sx={{ width: width }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      value={value}
      onChange={handleChange}
      filterOptions={handleFilterOptions}
      getOptionLabel={handleGetOptionLabel}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          size="small"
          placeholder={placeholder}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default InputWithPopup;
