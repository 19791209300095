import React, { useState } from "react";
import { Header, TagsListWithFilter, VideoTile } from "components";
import { useTagsList, useFilteredVideoList } from "hooks";
import InputWithPopup from "components/InputWithPopup/InputWithPopup";
import { videoApi } from "api";
import "./Tagging.scss";

const Tagging = () => {
  const { filteredVideoList, tagsFilter, applyTagsFilter, creators, selectCreator, updateVideo, reloadVideoList } =
    useFilteredVideoList();
  const { tagsList, createTag } = useTagsList();
  const [selectedVideoId, setSelectedVideoId] = useState();

  const handleVideoTileClick = (video) => {
    selectedVideoId === video._id && setSelectedVideoId(null);
    selectedVideoId !== video._id && setSelectedVideoId(video._id);
  };

  const handleConvertClick = async (videoId) => {
    await videoApi.convert(videoId).catch();
    reloadVideoList();
  };

  return (
    <>
      <Header />
      <div className="tagging-wrapper">
        <div className="left-block">
          <div className="search-channel-input">
            <InputWithPopup
              rows={creators.map((creator) => {
                return { _id: creator._id, name: creator.channelName };
              })}
              onSelect={(row) => selectCreator(row?._id)}
              placeholder={"Поиск по автору"}
              resetInputOnSelect={false}
              width={250}
              variant="outlined"
            />
          </div>
          <TagsListWithFilter list={tagsList} filter={tagsFilter} onTagFilterChange={applyTagsFilter} />
        </div>
        <div className="main-block">
          {filteredVideoList.map((video) => (
            <VideoTile
              key={video._id}
              selected={video._id === selectedVideoId}
              onCreateTag={createTag}
              onClick={handleVideoTileClick}
              video={video}
              onSave={updateVideo}
              onConvertClick={handleConvertClick}
              tagsList={tagsList}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Tagging;
