import React, { useEffect, useState } from "react";
import { ReactComponent as IconRemoveTag } from "assets/Icons/RemoveTag.svg";
import "./TagEdit.scss";

const TagEdit = ({ active, videoTag, tagName, onDelete, onWeightChange }) => {
  const [weightInput, setWeightInput] = useState(0);

  useEffect(() => {
    videoTag && setWeightInput(videoTag.weight);
  }, [videoTag, videoTag.weight]);

  return (
    <div className="tags-wrapper">
      <div className="tag-input">{tagName}</div>
      <input
        value={weightInput}
        className="parameter-input"
        type="number"
        disabled={!active}
        onChange={(e) => setWeightInput(e.target.value)}
        onBlur={(e) => onWeightChange(videoTag, e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && onWeightChange(videoTag, e.target.value)}
      />
      <button
        className={`remove-tag-btn ${!active ? "remove-tag-btn-none" : ""}`}
        onClick={() => onDelete && onDelete(videoTag)}
      >
        <IconRemoveTag />
      </button>
    </div>
  );
};

export default TagEdit;
