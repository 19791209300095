import React, { useState, useRef, useContext, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { ReactComponent as SignIn } from "assets/Icons/SignIn.svg";
import { ReactComponent as SignOut } from "assets/Icons/SignOut.svg";
import { ReactComponent as Logo } from "assets/Icons/Logo.svg";
import User from "components/Header/User/User";
import { useAuthContext } from "auth";
import "./Header.scss";
import translate from "../../i18n/translate";
import { SelectItem } from "components";
import useOnClickOutside from "./useOnClickOutside/useOnClickOutside";
import { LocaleContext } from "i18n/LanguageProvider";
import { ROLE_ADMIN } from "constants";
import { ReactComponent as EditAccount } from "assets/Icons/EditAccount.svg";

const Header = () => {
  const [menu, setMenu] = useState(false);
  const { user, logout } = useAuthContext();
  const location = useLocation();
  const myVideoUrl = location.pathname.includes("myvideo");
  const OnClickOutsideRef = useRef();
  useOnClickOutside(OnClickOutsideRef, () => setMenu(false));
  const { supportedLocales, locale, onChangeLocale } = useContext(LocaleContext);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userRoleAdmin = user.roles.find((item) => item === ROLE_ADMIN);
    userRoleAdmin ? setIsAdminRole(true) : setIsAdminRole(false);
  }, [user]);

  return (
    <div className="header-wrapper">
      <div className="header">
        <NavLink exact={"true"} to="/" className="logo">
          <div className="logo">
            <Logo />
          </div>
        </NavLink>
        <div className="buttons-box">
          <SelectItem
            value={locale.value}
            selectLabel={locale.name}
            onChange={onChangeLocale}
            menuItems={supportedLocales}
          />
          {!user && (
            <Button component={NavLink} to="/signup" state={{ from: location.pathname }}>
              {translate("header_button_registration")}
            </Button>
          )}

          {!user && (
            <Button component={NavLink} to="/signin" state={{ from: location.pathname }} endIcon={<SignIn />}>
              {translate("header_button_signin")}
            </Button>
          )}

          {isAdminRole && (
            <>
              <Button component={NavLink} to="/admin">
                {translate("header_button_admin")}
              </Button>
              <Button component={NavLink} to="/tagging">
                {translate("header_button_tagging")}
              </Button>
              <Button component={NavLink} to="/tagseditor">
                {translate("header_button_tagseditor")}
              </Button>
            </>
          )}

          {user && (
            <Button component={NavLink} to="/myvideo">
              {translate("header_button_myvideo")}
            </Button>
          )
            ? !myVideoUrl && (
                <Button component={NavLink} to="/myvideo">
                  {translate("header_button_myvideo")}
                </Button>
              )
            : ""}
          <div className="user-box">
            <div className={menu ? "overlay-user" : ""} />
            <User
              disabled={!user}
              avatarIcon={user?.profile?.picture ? user.profile.picture : ""}
              userName={
                !user
                  ? translate("header_user_userName_1")
                  : user.profile?.firstName
                  ? user.profile.firstName
                  : translate("header_user_userName_2")
              }
              onClick={() => setMenu(true)}
              children={
                <div className={`dropdownmenu ${menu && "active-menu"}`} ref={OnClickOutsideRef}>
                  {user && (
                    <div className="edit-account-btn-wrapper">
                      <Button
                        onClick={() => {
                          navigate("/edit_account");
                        }}
                        startIcon={<EditAccount />}
                      >
                        {translate("header_button_edit_account")}
                      </Button>
                    </div>
                  )}
                  {user && (
                    <div className="exit-btn-wrapper">
                      <Button
                        onClick={() => {
                          logout();
                        }}
                        startIcon={<SignOut />}
                      >
                        {translate("header_button_exit")}
                      </Button>
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
