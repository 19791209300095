import React, { useContext } from "react";
import { SelectItem } from "components";
import { LocaleContext } from "i18n/LanguageProvider";
import { Link } from "react-router-dom";
import { Button, TextField, InputAdornment } from "@mui/material";
import Alert from "@mui/material/Alert";
import { ReactComponent as IconAvatar } from "assets/Icons/Avatar.svg";
import { useCredentialsValidation } from "hooks";
import translate from "i18n/translate";
import usePasswordRecovery from "auth/usePasswordRecovery";
import "./AuthPage.scss";

const PasswordForgot = () => {
  const { supportedLocales, locale, onChangeLocale } = useContext(LocaleContext);
  const { validationErrors, credentials, setCredential, validateCredentials, validationSuccessful } =
    useCredentialsValidation();
  const { status, sendResetPasswordRequest } = usePasswordRecovery();

  return (
    <div className="auth-page-wrapper">
      <div className="lang-switcher-style">
        <SelectItem
          value={locale.value}
          selectLabel={locale.name}
          onChange={onChangeLocale}
          menuItems={supportedLocales}
        />
      </div>

      <div className="auth-form">
        {!status.success && (
          <>
            <p className="page-label">{translate("forgotPassword_pageLabel_label")}</p>
            <p className="page-text">{translate("forgotPassword_pageText_text")}</p>
            <div className="text-field-box">
              <TextField
                autoFocus
                required
                name="email"
                type="email"
                onChange={(e) => setCredential("email", e.target.value)}
                onBlur={(e) => e.target.value && validateCredentials()}
                error={Boolean(validationErrors.email)}
                helperText={validationErrors.email}
                className="text-field"
                placeholder={translate("forgotPassword_textField_email_placeholder")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconAvatar />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button
              className="big-button"
              disabled={!validationSuccessful}
              onClick={() => sendResetPasswordRequest(credentials.email, locale.value)}
            >
              {translate("forgotPassword_button_continue")}
            </Button>

            <div className="no-account">
              <span>{translate("forgotPassword_span_rememberPassword")}</span>
              <Link to={"/creator/signin"} className="link">
                {translate("forgotPassword_signinLinkText_text")}
              </Link>
            </div>
          </>
        )}

        {status.error && <Alert severity="error">{status.error}</Alert>}

        {status.success && !status.error && (
          <p className="success-label">
            {translate("forgotPassword_successLabel_label")} {credentials.email}
          </p>
        )}
        {status.success && !status.error && (
          <Link to={"/"} className="redirect-success-link">
            {translate("forgotPassword_BulbeeLinkText_text")}
          </Link>
        )}
      </div>
    </div>
  );
};

export default PasswordForgot;
