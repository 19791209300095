import React from "react";
import { CircularProgress } from "@mui/material";
import CardStyled from "components/CardStyled/CardStyled";
import { ReactComponent as Empty } from "assets/Icons/NoImage.svg";

const Thumbnail = ({ image, isLoading, onClick, selected }) => {
  return (
    <CardStyled
      className={selected ? "thumb-wrapper selected" : "thumb-wrapper"}
      onClick={(e) => onClick && onClick(e)}
    >
      {isLoading ? (
        <CircularProgress
          sx={{
            color: (theme) => (theme.palette.mode === "light" ? "#4cbfff" : "#308fe8"),
            animationDuration: "550ms",
          }}
          size={60}
          thickness={4}
        />
      ) : !image ? (
        <Empty />
      ) : (
        <img src={`${image}`} alt="" />
      )}
    </CardStyled>
  );
};

export default Thumbnail;
