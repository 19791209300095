import "./policy.scss";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { SelectItem } from "components";
import { LocaleContext } from "i18n/LanguageProvider";
import translate from "i18n/translate";

const ShortLicence = () => {
  const { supportedLocales, locale, onChangeLocale } = useContext(LocaleContext);
  return (
    <div className="article-wrapper">
      <div className="lang-switcher-style">
        <SelectItem
          value={locale.value}
          selectLabel={locale.name}
          onChange={onChangeLocale}
          menuItems={supportedLocales}
        />
      </div>
      <div className="article-body">
        <div className="article-title">
          {translate("uaShortLicence_title")}
          <div className="article-subtitle for-title">{translate("uaShortLicence_article_subtitle")}</div>
        </div>
        <div className="article-date">{translate("uaLicence_articleDate")}</div>
        <div className="article">{translate("uaShortLicence_article_1")}</div>
        <div className="article">{translate("uaShortLicence_article_2")}</div>
        <div className="article">{translate("uaShortLicence_article_3")}</div>
        <div className="article">{translate("uaShortLicence_article_4")}</div>
        <div className="article">{translate("uaShortLicence_article_5")}</div>
        <div className="article">{translate("uaShortLicence_article_6")}</div>
      </div>
      <div className="article-body content-right">
        <NavLink to="/full-licence">
          {translate("uaShortLicence_title")} {translate("uaShortLicence_tooltip")}
        </NavLink>
      </div>
    </div>
  );
};

export default ShortLicence;
