import React from "react";
import "./DependencyTag.scss";

const DependencyTag = ({ tag, tagName, style }) => {
  return (
    <div className="not-active-tag-wrapper" style={style}>
      <div className="not-active-tag-label">{tagName}</div>
    </div>
  );
};

export default DependencyTag;
