import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";

const thumbnailsUtils = {
  createAsBlobs: (videoFile, count) => {
    return generateVideoThumbnails(videoFile, count - 1)
      .then((res) => {
        return base64toBlobMultiple(res);
      })
      .catch();
  },
};

const base64toBlobMultiple = (b64array) => {
  return Promise.all(b64array.map((element) => base64toBlob(element)));
};

const base64toBlob = (base64Data) => {
  return fetch(base64Data)
    .then((res) => res.blob())
    .catch();
};

export default thumbnailsUtils;
