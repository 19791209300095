import React, { useEffect, useState } from "react";
import { ReactComponent as IconAddAvatar } from "assets/Icons/AddAvatar.svg";
import translate from "i18n/translate";
import "./UserIcon.scss";

const UserIcon = ({ image, onChange, onClick }) => {
  const [inputActive, setInputActive] = useState();

  useEffect(() => {
    setInputActive(image || !image);
  }, [image]);

  return (
    <div className={`user-icon-wrapper ${image ? "user-icon-wrapper-hovered" : ""}`}>
      <div
        className="user-icon-content"
        onClick={(e) => {
          onClick && onClick(e);
          !inputActive && e.preventDefault();
        }}
      >
        <label htmlFor="file-input">
          {image ? (
            image && <img src={`${image}`} alt="add avatar" />
          ) : (
            <>
              <div className="user-icon">
                <IconAddAvatar />
                <p className="selectImagelabel">{translate("userThumbnail_label_add")}</p>
              </div>
            </>
          )}
          {inputActive && (
            <input
              id="file-input"
              onChange={(e) => {
                onChange && onChange(e.target.files[0]);
              }}
              type="file"
              accept="image/*"
            />
          )}
        </label>
      </div>
      <div className="user-icon-hover">
        <IconAddAvatar />
        <p className="selectImagelabel">{translate("userThumbnail_label_change")}</p>
      </div>
    </div>
  );
};

export default UserIcon;
