import React from "react";
import { ReactComponent as IconRemoveTag } from "assets/Icons/RemoveTag.svg";
import "./DependencyTagEdit.scss";

const DependencyTagEdit = ({ active, tag, tagName, onDelete }) => {
  return (
    <div className="tags-editor-wrapper">
      <div className="tag-input">{tagName}</div>
      <button
        className={`remove-tag-btn ${!active ? "remove-tag-btn-none" : ""}`}
        onClick={() => onDelete && onDelete(tag)}
      >
        <IconRemoveTag />
      </button>
    </div>
  );
};

export default DependencyTagEdit;
