import axios from "axios";

const usersApi = {
  getOne: (id) => {
    return axios.get(`/api/users/${id}`).then((res) => res.data.user);
  },

  getAll: () => {
    return axios.get("/api/users").then((res) => res.data.users);
  },

  update: (id, updatingData) => {
    
    return axios.put(`/api/users/${id}`, updatingData).then((res) => res.data.user);
  },

  uploadFiles: (id, files) => {
    const formData = new FormData();
    files.forEach((file) => formData.append(file.name, file.data));
    return axios.put(`/api/users/${id}`, formData).then((res) => res.data.user);
  },

  deleteOne: (id) => {
    return axios.delete(`/api/users/${id}`).then((res) => res.data.user);
  },
};

export default usersApi;
