import { useEffect, useState } from "react";

const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hours = h > 0 ? h + "h" : "";
  var minutes = m > 0 ? m + "m" : "";
  var seconds = s > 0 ? s + "s" : "0s";
  return hours + minutes + seconds;
};

const useUploadProgressData = () => {
  const [progressEvent, setProgressEvent] = useState();

  const [uploadProgressData, setUploadProgressData] = useState({
    totalKb: 0,
    loadedKb: 0,
    percentage: 0,
    uploadSpeed: "",
    timeTotal: 0,
    timeLeft: 0,
    timeStart: 0,
  });

  useEffect(() => {
    if (progressEvent) {
      const { loaded, total } = progressEvent;

      const totalKb = Math.round(total / 1000);
      const loadedKb = Math.round(loaded / 1000);
      const timeTotal = Math.round(Date.now() / 1000 - uploadProgressData.timeStart / 1000);

      const uploadSpeedKb = Math.round(loadedKb / timeTotal);

      const uploadSpeed =
        uploadSpeedKb < 1000
        ? `${uploadSpeedKb} Kb/s`
        : `${Number(uploadSpeedKb / 1000).toFixed(1)} Mb/s`;

      const timeLeft = Math.round((totalKb - loadedKb) / uploadSpeedKb);

      setUploadProgressData((prev) => {
        return {
          ...prev,
          totalKb: totalKb,
          loadedKb: loadedKb,
          percentage: Math.floor((loadedKb * 100) / totalKb),
          uploadSpeed: uploadSpeed,
          timeTotal: secondsToHms(timeTotal),
          timeLeft: secondsToHms(timeLeft),
        };
      });
    }
  }, [progressEvent, uploadProgressData.timeStart]);

  const setUploadStartTime = (timestamp) =>
    setUploadProgressData((prev) => {
      return { ...prev, timeStart: timestamp };
    });

  return [uploadProgressData, setUploadStartTime, setProgressEvent];
};

export default useUploadProgressData;
